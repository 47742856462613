import React, {useEffect, useState} from "react";
//import { Context } from "../index";
import { observer } from "mobx-react";
import { useNavigate } from 'react-router-dom'

// ReactTabulator
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import "react-tabulator/css/bootstrap/tabulator_bootstrap4.min.css"; // use Theme(s)

import { ReactTabulator } from 'react-tabulator'
//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

// Bootstrap 5
import Container from "react-bootstrap/Container";
import { NavDropdown } from "react-bootstrap";
import { Form } from 'react-bootstrap'
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {Button} from "react-bootstrap";

import {TABLEREPORT_ROUTE,CALENDAR_ROUTE,TABLE_ROUTE,COUNTERPARTIES_ROUTE,HOTELROOMS_ROUTE} from "../utils/const";

import {events_load,events_delete} from "../http/userApi";

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faClose } from '@fortawesome/free-solid-svg-icons'
//import { FaLock,FaLockOpen } from 'react-icons/fa';

import DateRangePicker from "react-bootstrap-daterangepicker"
import Modal_User from '../fullcalendar/Modl' 


export const ReactTabulatorJs = observer(() => {
    //const {user} = useContext(Context)
    const navigate = useNavigate()
    const [events,setevents] = useState();
    // Стейт loading
    const [loading, setLoading] = useState(false)

    // Итервал поиска
    const [startDate,setstartDate] = useState();
    const [endDate,setendDate] = useState();

    const [startDateSearch,setstartDateSearch] = useState();
    const [endDateSearch,setendDateSearch] = useState();
    
    const [SearchHiden,setSearchHiden] = useState(true);

    const [value, onChange] = useState([new Date(), new Date()]);

    //const DateRangePickerRef = React.createRef();

    //var date = new Date();

    // // удаление event
        const delete_events = async (id) => {
          try {
              console.log(id);
              let data;          
              data = await events_delete(id); 
              console.log(data);
              console.log('Удаление Event');
              Load_events();
          } catch (e) {
              //alert('False '+ e.response.data.message)
              //alert(e.response.data.message)
              alert('Ошибка Удаления Получателя!')
              //console.log(e.response.data)
          }
      
        }  

    var DelIcon = function(cell, formatterParams, onRendered){ //plain text value
      return "<button type='button' class='btn btn-outline-danger'><i title='Редактировать' class='fa fa-minus'></i><a></a></button>";
    };

    const columns_user = [
        { title: "Номер", field: "nomer", resizable:false, width: 110 , hozAlign: "center" , headerFilter:"input",headerFilterPlaceholder:"поиск...",},
        { title: "Место", field: "resourceId",resizable:false, width: 100 , hozAlign: "center" , headerFilter:"input",headerFilterPlaceholder:"поиск...",},
        { title: "Ф.И.О", field: "title", resizable:false, width: 160 , headerFilter:"input",headerFilterPlaceholder:"поиск...",},
        { title: "Номер Заказа", field: "order_number", resizable:false, width: 170 , headerFilter:"input",headerFilterPlaceholder:"поиск...", },
        { title: "Дата въезда", field: "start", resizable:false, width: 180 , hozAlign: "center" , headerFilter:"input",headerFilterPlaceholder:"поиск...",},
        { title: "Дата выезда", field: "end", resizable:false, width: 180 , hozAlign: "center" , headerFilter:"input",headerFilterPlaceholder:"поиск...",},
        { title: "Регион", field: "region", resizable:false, headerFilter:"input",headerFilterPlaceholder:"поиск...",formatter:"textarea"},
        { title: "Статус", field: "status", resizable:false, width: 140 , hozAlign: "center" , headerFilter:"input",headerFilterPlaceholder:"поиск...",},
        {formatter: DelIcon, width:70, hozAlign:"center",resizable:false,headerSort : false, 
        // фунция нажатие на иконки в таблице
          cellClick:function(e, cell){	
            if(cell.getData().status == 'заселен'){	
              alert('Нельзя удалять со статусом заселен');
            } else {
              let isPass = window.confirm("Удалить получателя?");
                if (isPass){
                  delete_events(cell.getData().id);               
                  //console.log(cell.getData().status);
                  //console.log(cell.getData().id);
                }            
            };           
          }

        }
      ];


      const options_user = {
        //height: 150,
        //movableRows: true,
        //movableColumns: true
        history:true,
        pagination:"local",
        paginationSize:100,
        paginationSizeSelector:[10, 50, 100],
        layout:"fitColumns", // fit columns to width of table (optional)
        layoutColumnsOnNewData : true
      };


      useEffect(() => { 
        //Load_events();
      },[])    

  const SearchEvent = ()=>{
    Load_events();
    //console.log(startDate)
    //console.log(endDate)
    //console.log(DateRangePickerRef)
  }


  const handleApply = (event, picker) => {
    setSearchHiden(true);
    setevents([]);
    ///console.log(picker.startDate);
    ///console.log(picker.value);
    ///console.log(event);
  }

  const handleCallback = (start, end, label)=> {
    //console.log(start, end, label);
    //console.log(start.format('DD-MM-YYYY'))
    //console.log(end.format('DD-MM-YYYY'))
    setstartDate(start.format('YYYY-MM-DD'));
    setendDate(end.format('YYYY-MM-DD'));
    setstartDateSearch(start.format('DD-MM-YYYY'));
    setendDateSearch(end.format('DD-MM-YYYY'));
  }


      // загрузка events
  const Load_events = async () => {
    try {
        let data1;
        //let data2;
        //let data3;
        setLoading(true);
        data1 = await events_load(startDate,endDate);
        setevents(data1.data_out);
        setLoading(false);

        //console.log(data1.data_out)
        //data2 = await load_resourceAreaColumns();
        //data3 = await load_resources();
        
        setSearchHiden(false)
        //console.log(data1.data_out)
        
        //setresources(data3.data_out)
        
        //successCallback(data1.data_out)
        //document.querySelector('.fc-timeline-slots').parentNode.parentNode.scrollTop = 0;
        
        
        //data3.data_out.map((item, key) => 
         // {if(item.status != 'disable')
         //       array.push(item)}      
        //);

        //console.log(array)

    } catch (e) {
        return [];
        //alert('False '+ e.response.data.message)
        //alert(e.response.data.message)
        //alert('Ошибка загрузки Events')
        //console.log(e.response.data)
    }
    
    

}

if (loading) {
  return <Modal_User close_modal={true}/>//<Spinner animation={"grow"}/>      
}


    return(
        <>      
        <Navbar bg="light rounded" variant="light" sticky="top" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">МЕНЮ</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link onClick={() => navigate(TABLEREPORT_ROUTE)}>Отчеты</Nav.Link>
              {/* <Nav.Link onClick={() => navigate(COUNTERPARTIES_ROUTE)}>Контрагенты</Nav.Link>
              <Nav.Link onClick={() => navigate(HOTELROOMS_ROUTE)}>Номера</Nav.Link> */}
              <Nav.Link onClick={() => navigate(CALENDAR_ROUTE)}>Календарь</Nav.Link>
              <Nav.Link onClick={() => navigate(TABLE_ROUTE)}>Таблица</Nav.Link>

              {/*<NavDropdown title="Вид" id="navbarScrollingDropdown">
                <NavDropdown.Item onClick={() => navigate(CALENDAR_ROUTE)}>Календарь</NavDropdown.Item>
                  <NavDropdown.Item  onClick={() => navigate(TABLE_ROUTE)}>
                  Таблица
                  </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Что-то другое
                </NavDropdown.Item>
              </NavDropdown>*/}
            </Nav>
            <div style={{paddingRight: "20px"}}>Поиск по интервалу </div>
            <Form className="d-flex">
            { /*<Form.Control
                type="search"
                placeholder="Поиск по календарю"
                className="me-2"
                aria-label="Search"
              />
             <Button variant="outline-success">Поиск</Button>*/}
             
              <DateRangePicker
                initialSettings={{ 
                  //startDate: date, 
                  //endDate: date,
                  //autoApply: true,
                  locale: {
                      format: "DD-MM-YYYY",
                      separator: " / ",
                      applyLabel: "Применить",
                      cancelLabel: "Назад",
                      daysOfWeek: [
                          "Вс",
                          "Пн",
                          "Вт",
                          "Ср",
                          "Чт",
                          "Пт",
                          "Сб"
                      ],
                      monthNames: [
                          "Январь",
                          "Февраль",
                          "Март",
                          "Апрель",
                          "Май",
                          "Июнь",
                          "Июль",
                          "Август",
                          "Сентябрь",
                          "Октябрь",
                          "Ноябрь",
                          "Декабрь"
                      ],
                      firstDay: 1
                  },
                }}
                onCallback={handleCallback}
                onApply={handleApply}
                //onChange={onChange} 
                value={value}
              >
              <input type="text" className="form-control" />
              </DateRangePicker>
              <Button variant="outline-success" onClick={SearchEvent}>Поиск</Button>   
            </Form>
            
          </Navbar.Collapse>
        </Container>
      </Navbar> 
      <br></br> 
      <h2 hidden={SearchHiden} >Результат поиска с {startDateSearch} по {endDateSearch}</h2>
      <br></br>
              <ReactTabulator
               data={events}
               columns={columns_user}
               options={options_user}
              />
        </>
    );
    
}); 

export default ReactTabulatorJs;
import React, { useContext } from "react";
import { Context } from "../index";
import {observer} from "mobx-react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { NavDropdown } from "react-bootstrap";
import { Form } from 'react-bootstrap'
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import {TABLEREPORT_ROUTE, ADMIN_ROUTE, SHOP_ROUTE, LOGIN_ROUTE, CABINET_ROUTE,HOTELROOMS_ROUTE, 
        CALENDAR_ROUTE,TABLE_ROUTE,WEBS0CKET_ROUTE,COUNTERPARTIES_ROUTE} from "../utils/const";
import {Button} from "react-bootstrap";
import {useNavigate} from 'react-router-dom'
import {logoutauth} from '../http/userApi'
import LogoImg from "../image/icon-hotel.png"

//import { FaLock,FaLockOpen } from 'react-icons/fa';


export const NavBarMenu = observer(() => {
    const {user} = useContext(Context)
    const navigate = useNavigate()

    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
        user.setRole({})
        logoutauth()
    }

  return(
    <>
    <Navbar bg="light rounded" variant="light" sticky="top" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#">МЕНЮ</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link onClick={() => navigate(TABLEREPORT_ROUTE)}>Отчеты</Nav.Link>
            {/* <Nav.Link onClick={() => navigate(COUNTERPARTIES_ROUTE)}>Контрагенты</Nav.Link>
            <Nav.Link onClick={() => navigate(HOTELROOMS_ROUTE)}>Номера</Nav.Link> */}
            <Nav.Link onClick={() => navigate(CALENDAR_ROUTE)}>Календарь</Nav.Link>
            <Nav.Link onClick={() => navigate(TABLE_ROUTE)}>Таблица</Nav.Link>

            {/*<NavDropdown title="Вид" id="navbarScrollingDropdown">
              <NavDropdown.Item onClick={() => navigate(CALENDAR_ROUTE)}>Календарь</NavDropdown.Item>
                <NavDropdown.Item  onClick={() => navigate(TABLE_ROUTE)}>
                Таблица
                </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Что-то другое
              </NavDropdown.Item>
             </NavDropdown>*/}

          </Nav>
          {/* <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Поиск по календарю"
              className="me-2"
              aria-label="Search"
            />
           <Button variant="outline-success">Поиск</Button>       
          </Form> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );

  
}); 
import React, {useState, useContext } from "react";
import { Context } from "../index";
import {observer} from "mobx-react";
import {useNavigate} from 'react-router-dom'
import Container from "react-bootstrap/Container";

import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ReactTabulator, reactFormatter } from 'react-tabulator'

import {events_load_hotel,events_change_hotel} from "../http/userApi";

import Navbar from "react-bootstrap/Navbar";
//import { NavDropdown } from "react-bootstrap";
//import { Form } from 'react-bootstrap'
//import Nav from "react-bootstrap/Nav";
//import { NavLink } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown'
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import {FormGroup, Spinner} from "react-bootstrap";

import "react-tabulator/css/bootstrap/tabulator_bootstrap4.min.css"; // use Theme(s)

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faSignInAlt,faChartGantt} from "@fortawesome/free-solid-svg-icons";
import { createDuration } from "@fullcalendar/react";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeslash = <FontAwesomeIcon icon={faEyeSlash} />;


export const ReactTabulatorJsHotel = observer(() => {
    const {user} = useContext(Context)
    const navigate = useNavigate()
    const [events,setevents] = useState();
    // Показываем - скрываем модальное окно
    const [showModalStatus, setShowModalStatus] = useState(false);
    const [showdropbutton,setshowdropbutton] = useState(false);
    const [ProfileEdit1, setProfileEdit1] = useState([]);
    const [edit1, setEdit1] = useState('');
    const [disabledButton1, setdisabledButton1] = useState(true);
    const [tableid, settableid] = useState('');
    const [tablestatus, settablestatus] = useState('');
    const [color, setcolor] = useState('');

    // spinner закгрузки edit
    const [spinnerEdit1, setspinnerEdit1] = useState(false);
    const [spinnerButton1, setspinnerButton1] = useState(false);
    const spedit1 = <Spinner animation="border" />
    const spbutton1 = <Spinner animation="border" size="sm" />
    const SearchRef = React.createRef();

    
    const UseStatus = (statususe) => {
      const status = [
        {id: 1, status: 'заселен'},
      ];
  
      const status1 = [
        {id: 1, status: 'завершено'}
      ];
  
      switch(statususe) {
        case 'ожидает заезда': 
          return status

        case 'заселен': 
          return status1
        
        default: 
          return [];
      }
    };



    var printIcon = function(cell, formatterParams, onRendered){ //plain text value
        return "<button type='button' class='btn btn-outline-dark'><i title='Поменять статус' class='fa fa-refresh fa-spin'></i><a> Статус</a></button>";
    };


      const columns_hotel = [
        { title: "Номер", field: "nomer", resizable:false, width: 105 , hozAlign: "center" ,},
        { title: "Место", field: "resourceId",resizable:false, width: 100 , hozAlign: "center" , },
        { title: "Ф.И.О", field: "title", resizable:false, width: 160 , hozAlign: "center" ,formatter:"textarea"},
        { title: "Номер Заказа", field: "order_number", resizable:false, width: 170 , hozAlign: "center" ,},
        { title: "Дата въезда", field: "start", resizable:false, width: 180 , hozAlign: "center" , },
        { title: "Дата выезда", field: "end", resizable:false, width: 180 , hozAlign: "center" , },
        { title: "Регион", field: "region", resizable:false, hozAlign: "center" ,formatter:"textarea"},
        { title: "Статус", field: "status", resizable:false, width: 110, hozAlign: "center" ,formatter:"textarea",headerSort : false},
        {formatter:printIcon, title: "Поменять", width:141, hozAlign:"center", 
           cellClick:function(e, cell){
              //console.log(cell);
              setShowModalStatus(true);
              //console.log('status click');
              //console.log(cell.getData().id);
              settableid(cell.getData().id);
              settablestatus(cell.getData().status)              
        },headerSort : false},
      ];
    
      const options_hotel = {
        //height: 150,
        //movableRows: true,
        //movableColumns: true
        history:true,
        pagination:"local",
        paginationSize:100,
        paginationSizeSelector:[10, 50, 100],
        layout:"fitColumns", // fit columns to width of table (optional)
        layoutColumnsOnNewData : true
      };

      // Закрытие модального окна
      const handleCloseModalStatus = () => {
        setShowModalStatus(false)
        setEdit1('')
        setdisabledButton1(true);
        setspinnerButton1(false);
        //console.log('handleCloseModalStatus')
      };

      const handleSubmitModalStatus = async (event) => {     
        //setsearch(event.target.value);    
        try {
          let data1;
          let data2;
          var status1 = 'ожидает заезда';
          var status2 = 'заселен';
          var search = SearchRef.current.value;//event.target.value; 

          // if(edit1 = 'заселен'){
          //    setcolor('#FF4500');
          // }
          // if(edit1 = 'завершено'){
          //    setcolor('#32CD32');
          // }

          console.log(edit1);

          setspinnerButton1(true);
          data1 = await events_change_hotel(tableid,edit1,color);
          data2 = await events_load_hotel(status1,status2,search);
          setspinnerButton1(false);
          setShowModalStatus(false); 
          setEdit1('') 
          setevents(data2.data_out); 
          //setevents(data.data_out);
          console.log(data1.data_out)
          //console.log(event.target.value);
          //console.log(data.data_out);
        } catch (e) {
          setspinnerButton1(false);
         //alert('False '+ e.response.data.message)
         //alert(e.response.data.message)
         //alert(e.response.data)
          //console.log(e.response.data)
        }
      };

      // изменение Edit1 Input "Выберите статус"
      const handleOnChangeEdit1 = (event) => {
         //setEdit6(event.target.value)
         //console.log('handleOnChangeEdit1')
         //console.log(event.target.value)
         setdisabledButton1(true);   
         setEdit1('')
         var card = document.getElementById("card");
         card.style.height = "120px";
      }

      const onSelectEdit1 = async (event,eventKey) => {  
        //console.log(event)
        //setdisabledButton1(false);
        //console.log('выбранн item в dropdown');
        var card = document.getElementById("card");
        card.style.height = "120px";
        setEdit1(eventKey.target.firstChild.textContent)
        if(eventKey.target.firstChild.textContent != ''){
          setdisabledButton1(false);
        } else {
          setdisabledButton1(true);
        }    
      }
      
      const onCloseEdit1 = (isOpen) => {
        setshowdropbutton(isOpen)
        var card = document.getElementById("card");
        card.style.height = "120px";
        //console.log('onCloseEdit1')
      }

      const DropDownClickEdit1 = async () => {
      //console.log('нажата кнопка DropDownClick');  
      if (showdropbutton){
        setshowdropbutton(false)
        var card = document.getElementById("card");
        card.style.height = "120px";
      } else {
        setshowdropbutton(true)
        var card = document.getElementById("card");
        card.style.height = "200px";
      }
      }

      // поиск events
      const handleOnChangesearch = async (event) => {
        //setsearch(event.target.value);    
         try {
           var status1 = 'ожидает заезда';
           var status2 = 'заселен';
           var search = SearchRef.current.value;//event.target.value; 
           let data;
           setspinnerEdit1(true);
           data = await events_load_hotel(status1,status2,search);
           setspinnerEdit1(false);
           setevents(data.data_out);
           //console.log(data.data_out)
           //console.log(event.target.value);
           //console.log(data.data_out);
         } catch (e) {
           setspinnerEdit1(false);
           //alert('False '+ e.response.data.message)
           //alert(e.response.data.message)
           //alert(e.response.data)
           alert('Reload Table Error');
           //console.log(e.response.data)
         }
          
       }

    return(
        <>              
     <Navbar bg="rounded" variant="light" sticky="top" expand="lg">
        <Container fluid>
          <Navbar.Brand>ПОИСК</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll"  >
           <Form className="d-flex" >
              <Form.Control
                type="search"
                ref={SearchRef}
                placeholder="Введите фамилию"
                className="me-3"
                aria-label="Search"
                onChange={handleOnChangesearch}
              />
              {spinnerEdit1 ?
                <div>{spedit1}</div>
                :
                <div></div>
              } 
          {/*  <DateRangePicker
                        initialSettings={{ startDate: '01/01/2020', endDate: '01/15/2020' }}
          >
              <input type="text" className="form-control" />
           </DateRangePicker>
          */}

            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <hr></hr>
      <br></br>
      {events
         ? 
       <ReactTabulator
         data={events}
         columns={columns_hotel}
         options={options_hotel}
       />
         : null}
        
        <Modal id="myModalStatus"
          show={showModalStatus}
          onHide={handleCloseModalStatus}
          backdrop="static"
          keyboard={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>изменение статуса:  <br></br>  <div></div></Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Card id="card">
                  <Card.Body>  
                <Form.Label>Выберите статус</Form.Label>
                  <Form.Group className="mb-3" controlId="formEdit1">                 
                             <InputGroup className="mb-3"> 
                                <Form.Control 
                                  type="search" 
                                  placeholder="" 
                                  required
                                  value={edit1} 
                                  onChange={handleOnChangeEdit1}
                                  //onKeyPress={handleKeypress}
                                  maxLength="50"
                                  //ref={node}
                                  autoComplete ="off"
                                  //disabled={disabledEdit6}
                                />
                             <Dropdown 
                                drop={'down'} 
                                show={showdropbutton} 
                                onSelect={onSelectEdit1} 
                                onToggle={onCloseEdit1}
                              >
                              <Dropdown.Toggle
                                onClick={DropDownClickEdit1}
                                //disabled={disabledButtonEdit6}
                              > 
                              </Dropdown.Toggle>
                              <Dropdown.Menu id="dropdown-menu">
                                {UseStatus(tablestatus).map((item) => {
                                            return (
                                              <Dropdown.Item key={item.id}>{item.status}</Dropdown.Item>
                                            )
                                   })}
                              </Dropdown.Menu>
                             </Dropdown>                                           
                             </InputGroup> 
                            </Form.Group>
                    </Card.Body>
               </Card>
          </Modal.Body>
          
          <Modal.Footer>
            <Button id="submit_status" variant="success" type="submit" onClick={handleSubmitModalStatus} disabled = {disabledButton1}>
              {spinnerButton1 ?
                <div>{spbutton1} Применить</div>
                :
                <div>Применить</div>
              } 
            </Button>   
          </Modal.Footer>

      </Modal>
        </>
    );
    
}); 

export default ReactTabulatorJsHotel;
import React, {useState,useContext,useEffect,useRef} from "react";
import { Context } from "../index";
import {observer} from "mobx-react";
import {useLocation, useNavigate} from "react-router-dom";
import {LOGIN_ROUTE,REGISTRATION_ROUTE,SHOP_ROUTE,PASSWORD_RECOVERY} from '../utils/const';
import { NavBarMenu } from '../components/NavBarMenu'
import moment from 'moment';
import DateRangePicker from "react-bootstrap-daterangepicker"


import FullCalendar from '@fullcalendar/react' 
import interactionPlugin, { Draggable } from "@fullcalendar/interaction" //Provides the click, touch, and dragging interactions.
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { formatDate } from '@fullcalendar/react'

import Modal_User from '../fullcalendar/Modl' 
import {load_info_event,events_load,events_change,events_create,events_delete,load_area,
       load_recipient,load_resources,load_resourceAreaColumns,load_room,load_place,load_interval_event,load_interval_free_event} from "../http/userApi";

import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
//import ProgressBar from 'react-bootstrap/ProgressBar';
//import Spinner from 'react-bootstrap/Spinner';

import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from 'react-bootstrap/DropdownToggle'
import DropdownMenu from 'react-bootstrap/DropdownMenu'
import DropdownItem from 'react-bootstrap/DropdownItem'


import axios from 'axios';

import Select from 'react-select';

import {FormGroup, Spinner} from "react-bootstrap";


import ReactDOM from 'react-dom';

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";


export const FullCalendarComponent = observer(() => {
  const {user} = useContext(Context)
  const location = useLocation()
  const navigate = useNavigate()
  // каледарь в поле edit 
  const [date, setDate] = useState(new Date());
  // Показываем - скрываем модальное окно
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4_Modal_User, setShow4_Modal_User] = useState(false);
  // Стейты переменные при добавлении
  const [calendarApi, setcalendarApi] = useState();
  const [event_title, settitle] = useState(false);
  const [event_id, setid] = useState();
  const [event_resourceId, setresourceId] = useState();
  const [event_start, setstart] = useState();
  const [event_start_moment, setstart_moment] = useState();
  const [event_end, setend] = useState();
  const [event_backgroundColor, setbackgroundColor] = useState(false);
  const [event_comments, setcomments] = useState(false);
  // Стейты переменные валидации полей
  const [validated, setValidated] = useState(false);
  // Стейты переменные добавления event
  const [edit1, setEdit1] = useState('')
  const [edit2, setEdit2] = useState('')
  const [edit3, setEdit3] = useState('')
  const [edit4, setEdit4] = useState('')
  const [edit5, setEdit5] = useState('')
  const [edit6, setEdit6] = useState('')
  const [edit7, setEdit7] = useState('')
  const [edit8, setEdit8] = useState('')
  const [edit9, setEdit9] = useState('')
  const [edit10, setEdit10] = useState('')
  const [edit11, setEdit11] = useState('')
  const [edit12, setEdit12] = useState('')
  // ref Fullcalendar
  const calendarRef = React.createRef();
  // Стейт loading
  const [loading, setLoading] = useState(false)
  // Стейт просмотр
  const [event_start_info, setstartinfo] = useState();
  const [event_end_info, setendtinfo] = useState();
  // Стейт 
  const node = React.createRef();
  const [ProfileEdit1, setProfileEdit1] = useState([]);
  const [ProfileEdit3, setProfileEdit3] = useState([]);
  const [ProfileEdit6, setProfileEdit6] = useState([]);
  const [ProfileEdit7, setProfileEdit7] = useState([]);
  // Стейт 
  const [resourceAreaColumns, setresourceAreaColumns] = useState();
  const [resources,setresources] = useState();
  // Стейт
  const [showdropbutton,setshowdropbutton] = useState(false);
  const [showdropbuttonEdit7,setshowdropbuttonEdit7] = useState(false);
  // Стейт
  const [array,setarray] = useState([]);
  // Стейт disabledEdit
  const [disabledEdit5, setdisabledEdit5] = useState(true);
  const [disabledEdit6, setdisabledEdit6] = useState(true);
  const [disabledEdit7, setdisabledEdit7] = useState(true);
  const [disabledButtonEdit6, setdisabledButtonEdit6] = useState(true);
  const [disabledButtonEdit7, setdisabledButtonEdit7] = useState(true);
  const [disabledButtonChange, setdisabledButtonChange] = useState(true);
  // spinner закгрузки edit
  const [spinnerEdit1, setspinnerEdit1] = useState(false);
  const [spinnerEdit3, setspinnerEdit3] = useState(false);
  const sp = <Spinner animation="border" size="sm" />
  // Стейт id выбранного места
  const [selectid, setselectid] = useState();
  
  useEffect(() => { 
    document.querySelector('.fc-timeline-slots').parentNode.parentNode.scrollTop = 0;
  },[])  



  // чистим поля
  const ClearFieldEdit = () => {
    setValidated(false);
    setEdit1('')
    setEdit2('')
    setEdit3('')
    setEdit4('')
    setEdit5('')
    setdisabledEdit5(true)
    setEdit6('')
    setdisabledEdit6(true)
    setdisabledButtonEdit6(true)
    setEdit7('')
    setEdit12('')
    setdisabledEdit7(true)
    setdisabledButtonEdit7(true)
  }



  const [value,setValue]=useState('');
  const handleSelect=(e)=>{
    console.log(e);
    setValue(e)
  }

  const clearRegion = () =>{
    setEdit1('');
    setdisabledButtonChange(false)
  }


  // Событие Click при выборе из списка
  const TextclickItemEdit6 = async (event) => {
      console.log('clickEdit6');
      
    setEdit6(event.target.value); 
    try {
     let data;
     data = await load_room(); 
     //console.log(data)
     setProfileEdit6(data.data_out);
    } catch (event) {
     //alert('False '+ e.response.data.message)
     //alert(e.response.data.message)
     alert('ошибка')
     //console.log(e.response.data)
    }   
   
   // if(event.target.value == ''){
   //  setProfileEdit6([])
   // } 
  }

  // Событие Click при выборе из списка
  //const TextclickItemEdit7 = (e) => {
  //    console.log('clickEdit6');
  //    setEdit7('Номер 235');
  //}

  // Событие Click при выборе из списка
  const clickItemEdit1 = (e) => {
    console.log('clickItem');
    console.log(e.target.innerText)
    setEdit1(e.target.innerText)
    setProfileEdit1([])
  }
  
  // Событие Click при выборе из списка
  const clickItemEdit3 = (e) => {
    console.log('clickItem');
    console.log(e.target.innerText)
    setEdit3(e.target.innerText)
    setProfileEdit3([])
  }

  // Событие Click при выборе из списка
  const clickItemEdit6 = (e) => {
    console.log('clickItem');
    console.log(e.target.innerText)
    setEdit6(e.target.innerText)
    setProfileEdit6([])
  }

  // Событие Click при выборе из списка
  //const clickItemEdit7 = (e) => {
  //  console.log('clickItem');
  //  console.log(e.target.innerText)
  //  setEdit7(e.target.innerText)
  //  setProfileEdit7([])
  //}

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    //console.log(e.charCode)
    if (e.charCode === 13) {
       var elems = document.getElementById("submit_event");
       elems.click();
    }
  };

  // Кнопка нажатия добавить на пустое место
  const handleSubmit1 = (event) => {
     //console.log(event)
     var form = document.getElementById("eventcreate");
     //console.log(form);
     //const form = event.currentTarget;
     //console.log(form.checkValidity())
    if (form.checkValidity() === false) {
       event.preventDefault();
       event.stopPropagation();    
    } else {
      setShow1(false)
      create_events();
      //console.log("mozhno zakrivat")
    }              
       setValidated(true);        
  };

  const handleSubmit2 = (event) => {
     //console.log(event)
     var form = document.getElementById("eventcreate");
     //console.log(form);
     //const form = event.currentTarget;
     //console.log(form.checkValidity())
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();    
    } else {
     create_events();
      //console.log("mozhno zakrivat")
    }            
      setValidated(true);          
  };

  const handleSubmit3 = (event) => {
     //console.log(event)
     var form = document.getElementById("eventcreate");
     //console.log(form);
     //const form = event.currentTarget;
     //console.log(form.checkValidity())
    if (form.checkValidity() === false) {
     event.preventDefault();
     event.stopPropagation();    
    } else {
      setShow3(false)
      create_events();
      console.log(edit1)


    //console.log("mozhno zakrivat")
    }           
    setValidated(true);        
  };

  // Кнопка изменение 

  const handleSubmit4 = (event) => {
    //console.log(event)
    var form = document.getElementById("eventcreate");
    //console.log(form);
    //const form = event.currentTarget;
    //console.log(form.checkValidity())
   if (form.checkValidity() === false) {
     event.preventDefault();
     event.stopPropagation();    
   } else {
    change_events();
     //console.log("mozhno zakrivat")
   }            
     setValidated(true);          
 };

  // изменение event
  const change_events = async () => {
    //console.log(selectid)
    try {
        let data;
        var CalendarApi = calendarRef.current.getApi()
        data = await events_change(edit3,selectid,edit1,edit2); 
        ClearFieldEdit();
        setShow2(false)
        setdisabledButtonChange(true)
        CalendarApi.refetchEvents();
        console.log(data);
    } catch (e) {
        //alert('False '+ e.response.data.message)
        //alert(e.response.data.message)
        alert('ошибка')
        //console.log(e.response.data)
    }

  }


  // создание event
  const create_events = async () => {
       console.log(edit1);
       console.log(edit2);
       console.log(edit3);
       console.log(selectid);
       console.log(event_start);
       console.log(event_end);
       console.log(event_backgroundColor);
       console.log(event_comments);
       console.log(edit6);
       console.log(edit12);
      try {
          let data;
          var CalendarApi = calendarRef.current.getApi()
          data = await events_create(edit3,selectid,event_start,event_end,event_backgroundColor,event_comments,edit6,edit1,edit2); 
          ClearFieldEdit();
          //setShow1(false)
          CalendarApi.refetchEvents();
          console.log(data);
      } catch (e) {
          //alert('False '+ e.response.data.message)
          //alert(e.response.data.message)
          alert('Ошибка Создания')
          //console.log(e.response.data)
      }
  
    }
 
    // удаление event
    const delete_events = async () => {
      try {
          //console.log(event_id);
          let data;
          var CalendarApi = calendarRef.current.getApi()
          data = await events_delete(event_id); 
          ClearFieldEdit();
          setShow2(false)
          CalendarApi.refetchEvents();
          //console.log(data);
          //console.log('Удаление Event');

      } catch (e) {
          //alert('False '+ e.response.data.message)
          //alert(e.response.data.message)
          alert('Ошибка удаления')
          //console.log(e.response.data)
      }
  
    }  
  

  // загрузка events
  const load_events = async (info, successCallback, failureCallback) => {
      try {
          let data1;
          let data2;
          let data3;
          //setLoading(true);
          data1 = await events_load(info.startStr,info.endStr);
          data2 = await load_resourceAreaColumns();
          data3 = await load_resources();
          //setLoading(false);
          setresourceAreaColumns(data2.data_out)
          //console.log(data1.data_out)
          //console.log('load_events')
          console.log(data2.data_out)
          console.log(data3.data_out)
          setresources(data3.data_out)
          
          successCallback(data1.data_out)
          
          document.querySelector('.fc-timeline-slots').parentNode.parentNode.scrollTop = 0;
          console.log('прокрутка');
      
          // data3.data_out.map((item, key) => 
          //   {if(item.status != 'disable')
          //         array.push(item)}      
          // );

          //console.log(array)

      } catch (e) {
          return [];
          //alert('False '+ e.response.data.message)
          //alert(e.response.data.message)
          alert('Ошибка загрузки Events');
          //console.log(e.response.data)
      }
  }

  // изменение Edit1 Input
  const handleOnChangeEdit1 = async (event) => {

   setEdit1(event.target.value);  

    try {
      // разблокируем сохранить
      //setdisabledButtonChange(false)
      let data;
      setspinnerEdit1(true);
      data = await load_area(event.target.value);
      setspinnerEdit1(false);
      if(data.data_out == null){
        setProfileEdit1([]);
      } 
        else {
          setProfileEdit1(data.data_out);
        }
      
    } catch (e) {
      setspinnerEdit1(false);
     //alert('False '+ e.response.data.message)
     //alert(e.response.data.message)
     //alert(e.response.data)
      console.log(e.response.data)
    }

      if(event.target.value == ''){
       setProfileEdit1([])
      } 

     
  }

  // изменение Edit2 Input "Введите Номер заказа" 
  const handleOnChangeEdit2 = (event) => {
    //setdisabledButtonChange(false)
    setEdit2(event.target.value)       
  }

  // изменение Edit3 Input "Введите Ф.И.О получателя" 
  const handleOnChangeEdit3 = async (event) => {
   //setdisabledButtonChange(false)
   setEdit3(event.target.value); 
  //  try {
  //   let data;
  //   setspinnerEdit3(true);
  //   data = await load_recipient(event.target.value); 
  //   setspinnerEdit3(false);
  //   setProfileEdit3(data.data_out);
  //  } catch (e) {
  //   setspinnerEdit3(false);
  //   //alert('False '+ e.response.data.message)
  //   //alert(e.response.data.message)
  //   alert('ошибка')
  //   //console.log(e.response.data)
  //  }   
  
   if(event.target.value == ''){
    setProfileEdit3([])
   } 
  
  }

  // Для формы изменить ////////////////////
     // изменение Edit1 Input
  const handleOnChangeEdit1Select = async (event) => {

    setEdit1(event.target.value);  
 
     try {
       // разблокируем сохранить
       setdisabledButtonChange(false)
       let data;
       setspinnerEdit1(true);
       data = await load_area(event.target.value);
       setspinnerEdit1(false);
       if(data.data_out == null){
         setProfileEdit1([]);
       } 
         else {
           setProfileEdit1(data.data_out);
         }
       
     } catch (e) {
       setspinnerEdit1(false);
      //alert('False '+ e.response.data.message)
      //alert(e.response.data.message)
      //alert(e.response.data)
       console.log(e.response.data)
     }
 
       if(event.target.value == ''){
        setProfileEdit1([])
       } 
 
      
   }
 
   // изменение Edit2 Input "Введите Номер заказа" 
   const handleOnChangeEdit2Select = (event) => {
     setdisabledButtonChange(false)
     setEdit2(event.target.value)       
   }
 
   // изменение Edit3 Input "Введите Ф.И.О получателя" 
   const handleOnChangeEdit3Select = async (event) => {
    setdisabledButtonChange(false)
    setEdit3(event.target.value); 
   //  try {
   //   let data;
   //   setspinnerEdit3(true);
   //   data = await load_recipient(event.target.value); 
   //   setspinnerEdit3(false);
   //   setProfileEdit3(data.data_out);
   //  } catch (e) {
   //   setspinnerEdit3(false);
   //   //alert('False '+ e.response.data.message)
   //   //alert(e.response.data.message)
   //   alert('ошибка')
   //   //console.log(e.response.data)
   //  }   
   
    if(event.target.value == ''){
     setProfileEdit3([])
    } 
   
   }
 
  //////////////////////////////////////////


  // изменение Edit4 Input "Введите дату въезда"
   const handleOnChangeEdit4 = (event) => {

    let datefirst = new Date(event.target.value)
    
    setEdit4(event.target.value)
    if(event.target.value != '' && datefirst.getFullYear().toString().length == 4){    
      setdisabledEdit5(false)
      setEdit4(event.target.value)
      setstart(event.target.value + 'T12:00:00')  
    } 

    if(event.target.value != '' && edit5 != ''){
      setEdit5('')
      setdisabledEdit5(false)

      setEdit6('')
      setdisabledEdit6(true)
      setdisabledButtonEdit6(true) 

      setEdit7('')
      setdisabledEdit7(true)
      setdisabledButtonEdit7(true) 
    }

    if(event.target.value == ''){
      setEdit4(event.target.value)
      setEdit5('')
      setdisabledEdit5(true)
      
      setEdit6('')
      setdisabledEdit6(true)
      setdisabledButtonEdit6(true) 

      setEdit7('')
      setdisabledEdit7(true)
      setdisabledButtonEdit7(true)      
    }

  
  
  }

  // изменение Edit12 Input "Введите дату выезда"
  const handleOnChangeEdit12 = async (event) => {

    let date_event_start = new Date(event_start);
    let datefirst = new Date(event.target.value)
  
    setEdit12(event.target.value)
     if(date_event_start > datefirst){ 
       alert('Дата выезда не может быть меньше или равна даты въезда'); 
       setEdit12('');  
     } else {

      try {
        let data1,data2;
        setShow4_Modal_User(true);
        //console.log(clickInfo.event._def.resourceIds[0])
        data1 = await load_interval_event(event_start,event.target.value +'T12:00:00',edit6); 
        setShow4_Modal_User(false);
        console.log(data1.data_out[0].title)
        if(data1.data_out[0].title != edit7){
           alert('Интервал Занят');
        }
  


      } catch (e) {
        //alert('False '+ e.response.data.message)
        //alert(e.response.data.message)
          setShow4_Modal_User(false);
        //console.log(e.response.data)
      } 


     }
  }

  // изменение Edit5 Input "Введите дату выезда"
  const handleOnChangeEdit5 = async (event) => {
    let datefirst = new Date(edit4),
        datesecond = new Date(event.target.value)

        setEdit5(event.target.value)  
        

    if(datefirst < datesecond && datesecond.getFullYear().toString().length == 4){
        setend(event.target.value + 'T12:00:00')    
        setdisabledEdit6(false)
        setdisabledButtonEdit6(false)
      
      //try {
     //   let data;
      //  data = await load_interval_event(edit4 +'T12:00:00',event.target.value +'T12:00:00'); 
        //console.log(data)
        //setProfileEdit6(data.data_out);
    //    console.log(data.data_out)
     //   console.log(resources)

        setEdit6('')
        setEdit7('')
        setdisabledEdit7(true)
        setdisabledButtonEdit7(true)
        console.log('верный интервал')
       //} catch (event) {
        //alert('False '+ e.response.data.message)
        //alert(e.response.data.message)
    //    alert('ошибка')
        //console.log(e.response.data)
      // }   
      var form = document.getElementById("eventcreate");
      console.log(form)

    }

    if(datesecond.getFullYear().toString().length == 4){
       
   

    if(datefirst > datesecond ) {

      console.log('не верный интервал')
     // && datefirst.toString.length == datesecond.toString.length
        
        if (event.target.value !=''){
          setEdit4('')
          setEdit5('')
          setdisabledEdit5(true)
          setEdit6('')
          setdisabledEdit6(true)
          setdisabledButtonEdit6(true)
  
          setEdit7('')
          setdisabledEdit7(true)
          setdisabledButtonEdit7(true)
          alert('Не верный интервал дат')
        }  
               
    }

  }

  if (event.target.value ==''){
    setEdit5('')

    setEdit6('')
    setdisabledEdit6(true)
    setdisabledButtonEdit6(true)

    setEdit7('')
    setdisabledEdit7(true)
    setdisabledButtonEdit7(true)
  }  

     
  }


  // изменение Edit6 Input "Выберите Номер"
  const handleOnChangeEdit6 = (event) => {
    //setEdit6(event.target.value)
    setEdit6('')
    setdisabledEdit7(true)
    setdisabledButtonEdit7(true) 
  }
  // изменение Edit7 Input "Выберите место"
  const handleOnChangeEdit7 = (event) => {
  //setEdit6(event.target.value)
    setEdit7('')      
  }

  const DropDownClickEdit6 = async () => {

    var elements = document.getElementsByClassName("dropdown-menu"); 
    //console.log(elements)
    console.log('DropDownClick')
    try {
      let data;
      data = await load_room();   
      console.log(data)
      setProfileEdit6(data.data_out);
      if (showdropbutton){
        setshowdropbutton(false)
      } else {
        setshowdropbutton(true)
      }
      //console.log(data.data_out)
     } catch (event) {
      //alert('False '+ e.response.data.message)
      //alert(e.response.data.message)
      alert('ошибка')
      //console.log(e.response.data)
     }   
  }


  const DropDownClickEdit7 = async () => {
    if (showdropbutton){
      setshowdropbuttonEdit7(false)
    }else{
      setshowdropbuttonEdit7(true)
    }
    var elements = document.getElementsByClassName("dropdown-menu"); 
    //console.log(elements)
    console.log('DropDownClick')

   // try {
    // let data;
    // data = await load_place(); 
     //console.log(data)
    // setProfileEdit7(data.data_out);
     //console.log(data.data_out)
  //  } catch (event) {
     //alert('False '+ e.response.data.message)
     //alert(e.response.data.message)
  //   alert('ошибка')
     //console.log(e.response.data)
  //  }   
   
   // if(event.target.value == ''){
   //  setProfileEdit6([])
   // } 
    //console.log(showdropbutton)
  }


  const onCloseEdit6 = (isOpen) => {
    setshowdropbutton(isOpen)
    //console.log(isOpen)
  }

  const onSelectEdit6 = async (event,eventKey) => {  
     //console.log(event)
     setEdit6(eventKey.target.firstChild.textContent)

    try {
        let data;
        data = await load_interval_event(edit4 +'T12:00:00',edit5 +'T12:00:00',eventKey.target.firstChild.textContent); 
        //console.log(data)
        setProfileEdit6(data.data_out);
        setProfileEdit7(data.data_out);
        //console.log(edit4 + '--' + edit5 + '--' + eventKey.target.firstChild.textContent)
        console.log(data.data_out)
       //console.log(resources)

        setdisabledEdit7(false)
        setdisabledButtonEdit7(false)

        //setEdit6('')
        setEdit7('')
        //setdisabledEdit7(true)
        //setdisabledButtonEdit7(true)
    } catch (e) {
        //alert('False '+ e.response.data.message)
        //alert(e.response.data.message)
        alert('ошибка')
        //console.log(e.response.data)
    } 
  
  }


  const onCloseEdit7 = (isOpen) => {
    setshowdropbuttonEdit7(isOpen)
    //console.log(isOpen)
  }
  const onSelectEdit7 = (event,eventKey) => {  
     //console.log(node.current)
    // console.log(ProfileEdit7)
     setEdit7(eventKey.target.firstChild.textContent)
     ProfileEdit7.map((item,key) => {
        if(item.building == edit6 && item.title == eventKey.target.firstChild.textContent){
           //console.log(item.id)
           setselectid(item.id)
        }
     });
     //console.log('onSelect')
  }


  const add_event = () => {
    var node = document.getElementById("eventcreate");
    var elements = document.getElementsByClassName('form-control'); 

    
    //ReactDOM.findDOMNode(node).reset();
    //className = 'form-control is-invalid';
    //var a = ReactDOM.findDOMNode(node);
    //var parentDiv = a[1].parentElement;
    //elements[1].className = 'form-control is-invalid';
    //var error = document.createElement('div');
    //error.className = 'invalid-feedback';
    //parentDiv.insertBefore(error,elements[1].nextSibling);

    //console.log(elements.length);
    //console.log(a.length);
    //console.log(parentDiv);


    //ReactDOM.findDOMNode(this.eventcreate).reset();

     console.log(edit1)
     console.log(calendarApi)
     calendarApi.refetchEvents();
  }

  

  const resrender = (arg) => {
    //console.log(arg)
    // $(arg.el).find('.fc-datagrid-cell-cushion').html("<span>Гостиница SHANS_HOTEL</span>");
    //arg.el.innerHTML = `Гостиница  <Button variant="warning">Warning</Button>`
  };

  // 1 modal
  const handleClose1 = () => {
     ClearFieldEdit()
     setShow1(false)
     setProfileEdit1([])
     setProfileEdit3([])
  };
  
  // 2 modal
  const handleClose2 = () => {
      // заблокируем сохранить
      setdisabledButtonChange(true)
      ClearFieldEdit()
      setShow2(false)
      setProfileEdit1([])
      setProfileEdit3([])
  };

  // 3 modal
  const handleClose3 = () => {
      ClearFieldEdit()
      setShow3(false)
      setProfileEdit1([])
      setProfileEdit3([])
  };
  
  const handleDel = () => {
    let isPass = window.confirm("Удалить получателя?");
    if (isPass){
      delete_events();
    } 
  };

  // показать modal1
  const handleShow1 = () => {
      console.log('modal1')
      setShow1(true)
      setProfileEdit1([])
      setProfileEdit3([])
  };

  // показать modal2
  const handleShow2 = () => {
      console.log('modal2')
      setShow2(true)
      setProfileEdit1([])
      setProfileEdit3([])
  };

  // показать modal3
    const handleShow3 = () => {
      console.log('modal3')
      setShow3(true)
      setProfileEdit1([])
      setProfileEdit3([])
  };

  const handleEventResize= (info) => {
    alert("Начало: " + info.event.start.toISOString() + "\n" + "Конец: " + info.event.end.toISOString());
    // if (!confirm("is this okay?")) {
    //   info.revert();
    // }
  }



  // нажатие на пустое место
  const handleDateSelect = async (selectInfo) => {
    //console.log(selectInfo);
    const options = {
      onOpenStart: () => {
        //console.log("Open Start");
      },
      onOpenEnd: () => {
        //console.log("Open End");
      },
      onCloseStart: () => {
        //console.log("Close Start");
      },
      onCloseEnd: () => {
        //console.log("Close End");
      },
     // inDuration: 250,
     // outDuration: 250,
     // opacity: 0.5,
     // dismissible: false,
     // startingTop: "4%",
     // endingTop: "10%"
    };
         let calendarApi = selectInfo.view.calendar 

      if (selectInfo.startStr.substring(11) == '00:00:00')
        {
             alert('Дата выезда не может быть датой заезда')
             calendarApi.unselect();
        } else {
          ClearFieldEdit();
          //let calendarApi = selectInfo.view.calendar 
          setcalendarApi(calendarApi);
          // присваеваем значения стейтам
          //settitle(title);
          setresourceId(selectInfo.resource._resource.id)
          setselectid(selectInfo.resource._resource.id)
          //selectid(selectInfo.resource._resource.id);
          setstart(selectInfo.startStr);
          setstart_moment(moment(selectInfo.startStr).format('DD-MM-YYYY HH:mm'));
          //console.log(selectInfo.resource._resource.title)
         
          // номер
          setEdit6(selectInfo.resource._resource.extendedProps.building);
          // место
          setEdit7(selectInfo.resource._resource.title);
          //console.log(selectInfo.resource.extendedProps.building)

          try {
            let data1,data2;
            setShow4_Modal_User(true);
            //console.log(clickInfo.event._def.resourceIds[0])
            console.log(selectInfo.startStr);
            console.log(selectInfo.resource.extendedProps.building);
            console.log(selectInfo.resource._resource.id);
            data1 = await load_interval_free_event(selectInfo.startStr,selectInfo.resource.extendedProps.building,selectInfo.resource._resource.id); 
            setShow4_Modal_User(false);
            //console.log(moment(data1.data_out).format('DD-MM-YYYY'));
            //console.log(data1.data_out);
                     
            var D = new Date(selectInfo.startStr);
            D.setDate(D.getDate() + 1);
            setstartinfo(moment(D).format('DD-MM-YYYY'));
            //console.log(selectInfo.startStr);

            setend(data1.data_out);
            console.log(data1);

           // if(data1.data_out == null){
           //   setEdit12('');
           // } else {
              setEdit12(moment(data1.data_out).format('DD-MM-YYYY'));
           // }
            
            
            handleShow1()
            
            // if(data1.data_out[0].title != edit7){
            //    alert('Интервал Занят');
            // }
      
    
    
          } catch (e) {
            //alert('False '+ e.response.data.message)
            //alert(e.response.data.message)
            alert('Ошибка load_interval_free_event')
            setShow4_Modal_User(false);
            //console.log(e.response.data)
          } 
          
        

          // прокрутка вверх fc-timeline-slots
          //const slots = document.querySelector('.fc-timeline-slots');
          //const scrollPosition = slots.parentNode?.parentNode?.scrollTop;
          

        }
            
          //setend(edit4+'T12:00:00');
          //setend(selectInfo.endStr);
          //setbackgroundColor('#F3750B');
          //setcomments('coments'); 
          

        //console.log(selectInfo.resource._resource.id);
        // let title = prompt('Введите имя event')

        //  calendarApi.unselect() // clear date selection
          //console.log(selectInfo);
        //  if (title) {
        //    calendarApi.addEvent({
        //      id: '1',
        //      resourceId: selectInfo.resource._resource.id,
        //      title,
        //      start: selectInfo.startStr,
        //      end: selectInfo.endStr,
        //      allDay: selectInfo.allDay
        //    })
        //  }
          

  }



  const handleEventClick = async (clickInfo) => {
    //setid(clickInfo.event._def.resourceIds[0]);
    setid(clickInfo.event.id);
    //console.log(clickInfo.event.id)
    //if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove()
    //}
    const options = {
      onOpenStart: () => {
        //console.log("Open Start");
      },
      onOpenEnd: () => {
        //console.log("Open End");
      },
      onCloseStart: () => {
        //console.log("Close Start");
      },
      onCloseEnd: () => {
        //console.log("Close End");
      },
      //inDuration: 250,
      //outDuration: 250,
      //opacity: 0.5,
      //dismissible: false,
     //startingTop: "4%",
      //endingTop: "10%"
    };


    // var date_event_click_start = new Date(clickInfo.event.startStr);
    // var date_event_click_end = new Date(clickInfo.event.endStr);

    // var date_event_click_start_itog = date_event_click_start.toLocaleDateString() + " " + date_event_click_start.toLocaleTimeString()
    // var date_event_click_end_itog = date_event_click_end.toLocaleDateString() + " " + date_event_click_end.toLocaleTimeString()
    // Дата заезда выезда
    setstartinfo(moment(clickInfo.event.startStr).format('DD-MM-YYYY HH:mm'));
    setendtinfo(moment(clickInfo.event.endStr).format('DD-MM-YYYY HH:mm'));
    //console.log(clickInfo.event.startStr)
    // clear edit
    ClearFieldEdit();
    //console.log('EVENT CLICK');
    //console.log(clickInfo.event);
    //console.log(clickInfo.event._def.resourceIds)


    try {
      let data;
      setShow4_Modal_User(true);
      //console.log(clickInfo.event._def.resourceIds[0])
      data = await load_info_event(clickInfo.event.id); 
      setShow4_Modal_User(false);
      //console.log(data.data_out)
      setEdit1(data.data_out[0].region)
      setEdit2(data.data_out[0].order_number)
      setEdit3(data.data_out[0].title)
      setEdit4(moment(data.data_out[0].start).format('DD-MM-YYYY HH:mm'))
      setEdit5(moment(data.data_out[0].end).format('DD-MM-YYYY HH:mm'))
      setEdit6(clickInfo.event.getResources()[0].id)
      setEdit7(clickInfo.event.getResources()[0].title)
      setEdit8(data.data_out[0].status)
      setEdit9(data.data_out[0].statusexpects)
      setEdit10(data.data_out[0].statusstart)
      setEdit11(data.data_out[0].statusend)
      setselectid(data.data_out[0].id)
      console.log(data.data_out[0])

      handleShow2();
      //console.log(data.data_out[0])
    } catch (e) {
      //alert('False '+ e.response.data.message)
      //alert(e.response.data.message)
        setShow4_Modal_User(false);
      //console.log(e.response.data)
    } 

    //console.log(clickInfo.view.calendar.currentDataManager.data.resourceStore);
    //console.log(clickInfo.event.getResources()[0].id);
    //console.log(clickInfo.event.getResources()[0].title);

    // Показать modal2
    
    //console.log('click не пустое место');
  }


  const handleApply = (event, picker) => {
    console.log(picker);
    //setend(moment(edit12).format('YYYY-MM-DD')+'T12:00:00');
    //setSearchHiden(true);
    //setSearchButton(false);
    //setevents([]);
    //console.log(picker.startDate);
    ///console.log(picker.value);
    ///console.log(event);
  }
  
  const handleCallback = (start, end, label)=> {
    console.log(start._d);
    setend(moment(start._d).format('YYYY-MM-DD')+'T12:00:00');
    //console.log(start.format('DD-MM-YYYY'))
    //console.log(end.format('DD-MM-YYYY'))
    //setstartDate(start.format('YYYY-MM-DD'));
    //setendDate(end.format('YYYY-MM-DD'));
    //setstartDateSearch(start.format('DD-MM-YYYY'));
    //setendDateSearch(end.format('DD-MM-YYYY'));
  }


   const eventDidMount = (info) => {
    //console.log(info.event);
    // {description: "Lecture", department: "BioChemistry"}
   }

   if (loading) {
    return <Modal_User close_modal={true}/>//<Spinner animation={"grow"}/>      
   }

  const handleSelectAllow = (info,select) =>{
    //console.log(info);
   // if (info.start < Date.now()) return false;
    //return true;   
    return true;
  }

  const handleResourceLaneDidMount = (arg) =>{   
     // arg.el.style.backgroundColor = '#'+Math.random().toString(16).substr(2,6)
  }

  const handleSlotLaneDidMount =(arg) =>{
    //console.log(arg.view.getCurrentData())
    //console.log(arg)
   // arg.el.bgColor = "red"
  }

  const handleSlotLabelDidMount =(arg) => {
    //console.log(arg)
  }

  const handleCancel = (event, picker) => {
          picker.element.val('');
  };


   return (
    <div className="calendar">   
    <br></br>
    {/* {loading ?
     <div></div>
    :
     <div>
       <Spinner variant="warning" animation="border" role="status">
        <span>Загрузка...</span>
       </Spinner>
     </div>
    } */}
    <div style={{textAlign: "center"}}><h2>Календарь</h2></div>
    <hr></hr>
    <NavBarMenu />
    <br></br>
    <Modal_User close_modal={show4_Modal_User}/>
    <FullCalendar
              locale ={`ru`}
              timeZone="Asia/Almaty"
              timeZoneParam="Asia/Almaty"
              //themeSystem='themeSystem'
              //themeSystem='Darkly'
              //themeSystem='standard'
              initialView= 'resourceTimelineWeek'
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives" 
              plugins={[resourceTimelinePlugin,interactionPlugin]}
              editable={false} // редактирование таблицы // enable draggable events
              selectable={true}
              stickyFooterScrollbar={true}
              selectAllow={handleSelectAllow}
              slotDuration= {
                {"hours": 12}
              }
              slotLabelInterval= {
                {"hours": 24}
              }
              //aspectRatio={'2.0'}
              //scrollTime={'12:00'}
              //nowIndicator={true}
              //selectable={true}
              //allDaySlot={false}
              //expandRows={true}
              //slotMinTime='12:00:00'
              //slotMaxTime='14:00:00'
              firstDay='1'
              //slotDuration= '0:30' 
              //buttonIcons={false} // Убираем иконки вперед /назад
              stickyHeaderDates = {true}
              resourceAreaWidth= '24%'
              resourceAreaHeaderContent = 'Гостиница SHANS_HOTEL' // Название ресурсов
              //viewSkeletonRender ={resrender}
              resourceAreaHeaderDidMount={resrender}
              //resourcesInitiallyExpanded = {false} // Разкрывает ресурсы
              //resourceGroupField='building'
              //resourceGroupField='groupId'
              //resourceAreaColumns={resourceAreaColumns}
              
              resourceAreaColumns={resourceAreaColumns}
              selectOverlap={{
                  function(event) {
                    return true;
                  }
              }}
             
              scrollTime= '00:00'
              height= {600}// высота

              buttonText = {{
                today: "Сегодня",
                month: "Месяц",
                year: "Год",
                week: "Неделя",
                day: "День",
                list: "Agenda",
               }
              }

              buttonIcons = {{
                prev: 'chevron-left',
                next: 'chevron-right',  
                prevYear: 'chevrons-left', // double chevron
                nextYear: 'chevrons-right' // double chevron  
              }}

              customButtons={{
                add_event: {
                  text: '+ Добавить заказ',
                  click: () =>{
                    handleShow3();
                    //const slots = document.querySelector('.fc-timeline-slots')
                    //const scrollPosition = slots.parentNode?.parentNode?.scrollTop;
                    //document.querySelector('.fc-timeline-slots').parentNode.parentNode.scrollTop = 0;
                    //console.log(scrollPosition)
                    //console.log(slots)
                  },
                }
              }} 

              headerToolbar={{
                left:'today prev,next add_event',
                center:'title',
                right: 'resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear',
              }}


              views={{
                resourceTimelineWeek: {
                  slotLabelContent: function(arg) {
                   // console.log(arg.level)
                    if(arg.level === 2){
                      //arg.text = '00:00 - 12:00 12:00 - 24:00'
                      const html = `<div class="d-flex flex-row  fluid">
                                     <div class="p-2">00:00 - 12:00</div>
                                     <div class="p-2 border-start">12:00 - 24:00</div>
                                    </div>`;
                       return {
                          html,
                       };
                      //arg.text ='<div>1</div>'
                       //arg.text = '00:00 - 12:00'
                       //console.log(arg.date +'_'+ arg.text)
                    }
                    if(arg.level === 2 && arg.text === 13){
                      //arg.text = '12:00 - 24:00'
                      //console.log(arg.date +'_'+ arg.text)
                    }
                    
          


                  },
                  titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
                  slotLabelFormat: [
                    {month: 'long'}, 
                    { 
                        day: 'numeric',
                        weekday: 'short'
                    },
                    {   
                        hour: 'numeric',
                        minute: '2-digit',
                        omitZeroMinute: true 
                    },
                  ]
                },
                resourceTimelineMonth: {
                  titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
                  slotLabelFormat: [
                    { day: 'numeric'},
                    { weekday: 'short'} // lower level of text
                  ]
                },
                resourceTimelineYear: {
                  titleFormat: { year: 'numeric'},
                  slotLabelFormat: [
                    { month: '2-digit'},
                    { day: '2-digit'} // lower level of text
                  ]
                },

              }}
              ref={calendarRef}
              //events={events}
              resources={resources}
              eventContent={renderEventContent} // custom render function
              eventClick={handleEventClick}
              eventDidMount={eventDidMount} 
              select={handleDateSelect}
              //eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
              eventResize={handleEventResize}
              initialEvents={load_events}
              resourceLaneDidMount= {handleResourceLaneDidMount}
              slotLaneDidMount = {handleSlotLaneDidMount}
              slotLabelDidMount = {handleSlotLabelDidMount}
              
      /> 
      {/*Модальное окно Select Event*/}
      <Modal id="myModal1"
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Добавление заказа:          
               <br></br>
               <div className="d-flex flex-column" style={{paddingTop:'15px'}}>
                <h6>Дата: <a style={{fontWeight:"normal",paddingLeft:"20px"}}>{event_start_moment}</a></h6>
                <h6>Номер: <a style={{fontWeight:"normal",paddingLeft:"4px"}}>{edit6}</a></h6> 
                <h6>Место: <a style={{fontWeight:"normal",paddingLeft:"8px"}}>{edit7}</a></h6>
               </div>
            </Modal.Title> 
          </Modal.Header>
          <Modal.Body>
               <Card >
                  <Card.Body>  

                          <Form id="eventcreate" noValidate validated={validated} onSubmit={handleSubmit1}>

                            <Form.Group className="mb-3" controlId="formEdit1">
                               <Form.Label>
                                {spinnerEdit1 ?
                                  <div>Регион {sp}</div>
                                :
                                  <div>Регион</div>
                                } 
                               </Form.Label>
                                <Form.Control 
                                //type="text"
                                as="textarea" rows={3} 
                                placeholder="Введите Регион"
                                required
                                value={edit1} 
                                onChange={handleOnChangeEdit1}
                                //onKeyPress={handleKeypress}
                                maxLength="200"
                                ref={node}
                                />
                              <ListGroup  onClick={clickItemEdit1}>
                              {ProfileEdit1.map((item) => {
                                            return (
                                              <a
                                                className="list-group-item list-group-item-action"
                                                key={item.id}                        
                                              >
                                                {item.edit1}
                                              </a>
                                            )
                              })}
                              </ListGroup>
                              <Form.Control.Feedback type="invalid">
                                  Поле является обязательным.
                              </Form.Control.Feedback> 
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit2">
                               <Form.Label>Номер заказа</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Введите Номер заказа" 
                                required
                                value={edit2} 
                                onChange={handleOnChangeEdit2}
                               //onKeyPress={handleKeypress}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Поле является обязательным.
                                </Form.Control.Feedback> 
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit3">
                               <Form.Label>Ф.И.О получателя</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Введите Ф.И.О получателя" 
                                required
                                value={edit3} 
                                onChange={handleOnChangeEdit3}
                                //onKeyPress={handleKeypress}
                                ref={node}
                                />
                              <ListGroup  onClick={clickItemEdit3}>
                              {ProfileEdit3.map((item) => {
                                            return (
                                              <a
                                                className="list-group-item list-group-item-action"
                                                key={item.id}                        
                                              >
                                                {item.edit3}
                                              </a>
                                            )
                              })}
                              </ListGroup> 
                                <Form.Control.Feedback type="invalid">
                                  Поле является обязательным.
                                </Form.Control.Feedback> 
                            </Form.Group>

                            {/* <Form.Group className="mb-3" controlId="formEdit12">
                               <Form.Label>Выберите дату выезда</Form.Label>
                                <Form.Control 
                                  type="date" 
                                  placeholder="Введите дату выезда"
                                  required 
                                  value={edit12}
                                  onChange={handleOnChangeEdit12}
                                  //onKeyPress={handleKeypress}
                                  //onChange={(e) => setDate(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Поле является обязательным.
                                </Form.Control.Feedback> 
                            </Form.Group> */}
      <Form.Group className="mb-3" controlId="formEdit12">
         <Form.Label>Выберите дату выезда</Form.Label>
            <DateRangePicker                           
              initialSettings={{ 
                singleDatePicker: true,
                startDate: edit12, 
                drops:"up",
                minDate: event_start_info,
                maxDate: edit12,
                //endDate: date,
                //autoApply: true,
                locale: {
                    format: "DD-MM-YYYY",
                    separator: " / ",
                    applyLabel: "Применить",
                    cancelLabel: "Назад",
                    daysOfWeek: [
                        "Вс",
                        "Пн",
                        "Вт",
                        "Ср",
                        "Чт",
                        "Пт",
                        "Сб"
                    ],
                    monthNames: [
                        "Январь",
                        "Февраль",
                        "Март",
                        "Апрель",
                        "Май",
                        "Июнь",
                        "Июль",
                        "Август",
                        "Сентябрь",
                        "Октябрь",
                        "Ноябрь",
                        "Декабрь"
                    ],
                    firstDay: 1
                },
              }}
              onCallback={handleCallback}
              onApply={handleApply}
              //onChange={onChange} 
              //onCancel={handleCancel}
              value={value}
            >
            <input type="text" className="form-control" />
            </DateRangePicker>
      </Form.Group>
                          </Form>
                          
                  </Card.Body>
               </Card>
          </Modal.Body>
          
          <Modal.Footer>
            <Button id="close_event1" variant="secondary" onClick={handleClose1}>
              Закрыть
            </Button>
            <Button id="submit_event" variant="danger" type="submit" onClick={handleSubmit1}>
              Добавить
            </Button>
          </Modal.Footer>

      </Modal>

      {/*Модальное окно Click Event Изменение Заказа*/}
      <Modal id="myModal2"
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title><div>Изменение данных:</div></Modal.Title>
          </Modal.Header>
          <Modal.Body>
               <Card >
                  <Card.Body>  

                          <Form id="eventcreate" noValidate validated={validated} onSubmit={handleSubmit4}>

                            <Form.Group className="mb-3" controlId="formEdit1">
                               <Form.Label>
                                {spinnerEdit1 ?
                                  <div>Регион {sp}</div>
                                :
                                  <div>Регион</div>
                                } 
                               </Form.Label>
                                <Form.Control 
                                //type="text" 
                                as="textarea" rows={3} 
                                placeholder="Введите Регион"
                                required
                                value={edit1} 
                                onChange={handleOnChangeEdit1Select}
                                //onKeyPress={handleKeypress}
                                maxLength="200"
                                ref={node}
                                />
                                <ListGroup  onClick={clickItemEdit1}>
                              {ProfileEdit1.map((item) => {
                                            return (
                                              <a
                                                className="list-group-item list-group-item-action"
                                                key={item.id}                        
                                              >
                                                {item.edit1}
                                              </a>
                                            )
                              })}
                              </ListGroup> 
                              <Form.Control.Feedback type="invalid">
                                  Поле является обязательным.
                              </Form.Control.Feedback> 
                              <Button variant="light"  onClick={clearRegion} >Очистить поле Регион</Button>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit2">
                               <Form.Label>Номер заказа</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Введите Номер заказа" 
                                required
                                value={edit2} 
                                onChange={handleOnChangeEdit2Select}
                                //onKeyPress={handleKeypress}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit3">
                               <Form.Label>Ф.И.О получателя</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Введите Ф.И.О получателя" 
                                required
                                value={edit3} 
                                onChange={handleOnChangeEdit3Select}
                                //onKeyPress={handleKeypress}
                                ref={node}
                                />
                              <ListGroup  onClick={clickItemEdit3}>
                              {ProfileEdit3.map((item) => {
                                            return (
                                              <a
                                                className="list-group-item list-group-item-action"
                                                key={item.id}                        
                                              >
                                                {item.edit3}
                                              </a>
                                            )
                              })}
                              </ListGroup> 
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit4">
                               <Form.Label>Дата въезда</Form.Label>
                                <Form.Control 
                                type="text" 
                                //placeholder="Введите Номер заказа" 
                                required
                                value={edit4} 
                                onChange={handleOnChangeEdit4}
                                //onKeyPress={handleKeypress}
                                disabled = {true}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit5">
                               <Form.Label>Дата выезда</Form.Label>
                                <Form.Control 
                                type="text" 
                                //placeholder="Введите Номер заказа" 
                                required
                                value={edit5} 
                                onChange={handleOnChangeEdit5}
                                //onKeyPress={handleKeypress}
                                disabled = {true}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit6">
                               <Form.Label>Номер</Form.Label>
                                <Form.Control 
                                type="text" 
                                //placeholder="Введите Номер заказа" 
                                required
                                value={edit6} 
                                onChange={handleOnChangeEdit6}
                                //onKeyPress={handleKeypress}
                                disabled = {true}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit7">
                               <Form.Label>Место</Form.Label>
                                <Form.Control 
                                type="text" 
                                //placeholder="Введите Номер заказа" 
                                required
                                value={edit7} 
                                onChange={handleOnChangeEdit7}
                                //onKeyPress={handleKeypress}
                                disabled = {true}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit8">
                               <Form.Label>Текущий статус</Form.Label>
                                <Form.Control 
                                type="text" 
                                //placeholder="Введите Номер заказа" 
                                required
                                value={edit8} 
                                //onChange={handleOnChangeEdit8}
                                //onKeyPress={handleKeypress}
                                disabled = {true}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit9">
                               <Form.Label>Дата создания</Form.Label>
                                <Form.Control 
                                type="text" 
                                //placeholder="Введите Номер заказа" 
                                required
                                value={edit9} 
                                //onChange={handleOnChangeEdit8}
                                //onKeyPress={handleKeypress}
                                disabled = {true}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit10">
                               <Form.Label>Дата статуса заселен</Form.Label>
                                <Form.Control 
                                type="text" 
                                //placeholder="Введите Номер заказа" 
                                required
                                value={edit10} 
                                //onChange={handleOnChangeEdit8}
                                //onKeyPress={handleKeypress}
                                disabled = {true}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit11">
                               <Form.Label>Дата статуса завершено</Form.Label>
                                <Form.Control 
                                type="text" 
                                //placeholder="Введите Номер заказа" 
                                required
                                value={edit11} 
                                //onChange={handleOnChangeEdit8}
                                //onKeyPress={handleKeypress}
                                disabled = {true}
                                />
                            </Form.Group>



                          </Form>
                          
                  </Card.Body>
               </Card>
          </Modal.Body>
          
          <Modal.Footer>
            <Button id="del_event" variant="danger" onClick={handleDel}>
              Удалить
            </Button>
            <Button 
              id="submit_event_change" 
              variant="success" 
              type="submit" 
              onClick={handleSubmit4}  
              disabled = {disabledButtonChange}>
              Сохранить
            </Button>
          </Modal.Footer>

      </Modal>

      {/*Модальное окно кнопка добавить +*/}
      <Modal id="myModal3"
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Добавление заказа:  <br></br>  <div></div></Modal.Title>
          </Modal.Header>
          <Modal.Body>
               <Card >
                  <Card.Body>  

                          <Form id="eventcreate" noValidate validated={validated} onSubmit={handleSubmit3}>

                            <Form.Group className="mb-3" controlId="formEdit1">
                               <Form.Label> 
                                {spinnerEdit1 ?
                                  <div>Регион {sp}</div>
                                :
                                  <div>Регион</div>
                                }  
                                </Form.Label>      
                                <Form.Control 
                                //type="text"
                                as="textarea" rows={3} 
                                placeholder="Введите Регион"
                                required
                                value={edit1} 
                                onChange={handleOnChangeEdit1}
                                //onKeyPress={handleKeypress}
                                maxLength="200"
                                //onClick={getVal}
                                ref={node}
                                autoComplete ="off"
                                /> 
                              <ListGroup  onClick={clickItemEdit1}>
                              {ProfileEdit1.map((item) => {
                                            return (
                                              <a
                                                className="list-group-item list-group-item-action"
                                                key={item.id}                        
                                              > 
                                                {item.edit1}
                                              </a>
                                            )
                              })}
                              </ListGroup> 
                              <Form.Control.Feedback type="invalid">
                                  Поле является обязательным.
                              </Form.Control.Feedback>                      
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit2">
                               <Form.Label>Номер заказа</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Введите Номер заказа" 
                                required
                                value={edit2} 
                                onChange={handleOnChangeEdit2}
                                //onKeyPress={handleKeypress}
                                maxLength="50"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit3">
                               <Form.Label>
                               {spinnerEdit3 ?
                                  <div>Ф.И.О получателя {sp}</div>
                                :
                                  <div>Ф.И.О получателя</div>
                                }  
                               </Form.Label>
                          
                                <Form.Control 
                                type="text" 
                                placeholder="Введите Ф.И.О получателя" 
                                required
                                value={edit3} 
                                onChange={handleOnChangeEdit3}
                                onKeyPress={handleKeypress}
                                maxLength="50"
                                ref={node}
                                autoComplete ="off"
                                />
                              <ListGroup  onClick={clickItemEdit3}>
                              {ProfileEdit3.map((item) => {
                                            return (
                                              <a
                                                className="list-group-item list-group-item-action"
                                                key={item.id}                        
                                              >
                                                {item.edit3}
                                              </a>
                                            )
                              })}
                              </ListGroup>            
                            </Form.Group>

                            <Form.Group  className="mb-3" controlId="formEdit4">
                               <Form.Label>Дата въезда</Form.Label>
                                <Form.Control 
                                  type="date" 
                                  placeholder="Введите дату въезда"
                                  required 
                                  value={edit4}
                                  onChange={handleOnChangeEdit4}
                                  //onKeyPress={handleKeypress}
                                  //onChange={(e) => setDate(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit5">
                               <Form.Label>Дата выезда</Form.Label>
                                <Form.Control 
                                  type="date" 
                                  placeholder="Введите дату выезда"
                                  required 
                                  value={edit5}
                                  onChange={handleOnChangeEdit5}
                                  //onKeyPress={handleKeypress}
                                  disabled={disabledEdit5}
                                  //onChange={(e) => setDate(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formEdit6">                 
                             <Form.Label>Выберите Номер</Form.Label>
                             <InputGroup className="mb-3"> 
                                <Form.Control 
                                  type="text" 
                                  placeholder="" 
                                  required
                                  value={edit6} 
                                  onChange={handleOnChangeEdit6}
                                  //onKeyPress={handleKeypress}
                                  maxLength="50"
                                  ref={node}
                                  autoComplete ="off"
                                  disabled={disabledEdit6}
                                />
                             <Dropdown 
                                drop={'up'} 
                                show={showdropbutton} 
                                onSelect={onSelectEdit6} 
                                onToggle={onCloseEdit6}>
                              <Dropdown.Toggle
                                onClick={DropDownClickEdit6}
                                disabled={disabledButtonEdit6}
                              > 
                              </Dropdown.Toggle>
                              <Dropdown.Menu id="dropdown-menu" ref={node}>
                                {ProfileEdit6.map((item) => {
                                            return (
                                              <Dropdown.Item key={item.id}>{item.building}</Dropdown.Item>
                                            )
                                   })}
                              </Dropdown.Menu>
                             </Dropdown>                                           
                             </InputGroup> 
                            </Form.Group>



                            <Form.Group className="mb-3" controlId="formEdit7">                 
                             <Form.Label>Выберите место</Form.Label>
                             <InputGroup className="mb-3"> 
                                <Form.Control 
                                  type="text" 
                                  placeholder="" 
                                  required
                                  value={edit7} 
                                  onChange={handleOnChangeEdit7}
                                  //onKeyPress={handleKeypress}
                                  maxLength="50"
                                  ref={node}
                                  autoComplete ="off"
                                  disabled={disabledEdit7}
                                />
                              <Dropdown 
                                drop={'up'} 
                                show={showdropbuttonEdit7} 
                                onSelect={onSelectEdit7} 
                                onToggle={onCloseEdit7} 
                              >
                              <Dropdown.Toggle
                                onClick={DropDownClickEdit7}
                                disabled={disabledButtonEdit7} 
                              > 
                              </Dropdown.Toggle>
                              <Dropdown.Menu id="dropdown-menu" ref={node}>
                                {ProfileEdit7.map((item) => {
                                            return (
                                              <Dropdown.Item  key={item.id}>{item.title}</Dropdown.Item>
                                            )
                                   })}
                              </Dropdown.Menu>
                             </Dropdown>                                           
                             </InputGroup> 
                            </Form.Group>


                          </Form>
                          
                  </Card.Body>
               </Card>
          </Modal.Body>
          
          <Modal.Footer>
            <Button id="close_event1" variant="secondary" onClick={handleClose3}>
              Закрыть
            </Button>
            <Button id="submit_event" variant="danger" type="submit" onClick={handleSubmit3} disabled = {disabledButtonEdit7}>
              Добавить
            </Button>
          </Modal.Footer>

      </Modal>

    </div>
   );

   function renderEventContent(eventInfo) {
    return (
      <>
      <i>{eventInfo.event.title}</i>
      </>
    )
  
  }


});

export default FullCalendarComponent;
import {makeAutoObservable} from "mobx";

export default class UserStore {
    constructor() {
        this._isAuth = false
        this._user = {}
        this._role = {}
        this._email = false
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }
    setUser(user) {
        this._user = user
    }
    setRole(role){
        this._role = role
    }
    setEmail(email){
        this._email = email
    }


    get isAuth() {
        return this._isAuth
    }
    get user() {
        return this._user
    }
    get role(){
        return this._role
    }
    get email(){
        return this._email
    }
    
}

import React from 'react';

const CabinetUser = () => {

    return (
        <>
        <br></br>
        <div style={{textAlign: "center"}}><h2>Кабинет пользователя</h2></div>
        <hr></hr>
        </>
    );
};

export default CabinetUser;
import React, {useEffect, useState, useRef} from "react";
//import { Context } from "../index";
import { observer } from "mobx-react";
import { useNavigate } from 'react-router-dom'

// ReactTabulator
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import "react-tabulator/css/bootstrap/tabulator_bootstrap4.min.css"; // use Theme(s)

import { ReactTabulator } from 'react-tabulator'
//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

// Bootstrap 5
import Container from "react-bootstrap/Container";
//import { NavDropdown } from "react-bootstrap";
//import { Form } from 'react-bootstrap'
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {Button} from "react-bootstrap";
import Form from 'react-bootstrap/Form'

import {TABLEREPORT_ROUTE,CALENDAR_ROUTE,TABLE_ROUTE,COUNTERPARTIES_ROUTE,HOTELROOMS_ROUTE} from "../utils/const";

import {load_area_table} from "../http/userApi";

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faClose } from '@fortawesome/free-solid-svg-icons'
//import { FaLock,FaLockOpen } from 'react-icons/fa';

import Modal_User from '../fullcalendar/Modl' 


export const ReactTabulatorCounterpartiesJs = observer(() => {
  //const {user} = useContext(Context)
  const navigate = useNavigate()
  const [events,setevents] = useState([]);
  // Стейт loading
  const [loading, setLoading] = useState(false)

  // Итервал поиска
  const [startDate,setstartDate] = useState();
  const [endDate,setendDate] = useState();

  const [startDateSearch,setstartDateSearch] = useState();
  const [endDateSearch,setendDateSearch] = useState();
  
  const [SearchHiden,setSearchHiden] = useState(true);
  const [SearchButton,setSearchButton] = useState(true);


  const [value, onChange] = useState([new Date(), new Date()]);
  
  //const DateRangePickerRef = React.createRef();
  // ref table
  let TableRef = useRef(null);
  
  var printIcon = function(cell, formatterParams, onRendered){ //plain text value
    return "<button type='button' class='btn btn-outline-dark'><i style='color:red' title='Удалить' class='fa fa-trash-o'></i><a></a></button>";
  };

  var addIcon = function(cell, formatterParams, onRendered){ //plain text value
    return "<button type='button' class='btn btn-outline-dark'><i style='color:#F3750B' title='Редактировать' class='fa fa-edit'></i><a></a></button>";
  };



  const cellClick = (cell) =>{
    alert('111');
    console.log("CellClick called");
    console.log("cell Click id: ", cell);
  };

  const dateEditor = (cell,onRendered,success,cancel) =>{
    let cellValue_Alt = cell.getValue(); // значение ячейки при нажатии
    let cellValue_getColumn = cell.getColumn()._column.field;

    var cellValue = cell.getValue();
    console.log(cellValue);

     var input = document.createElement("input");

     input.setAttribute("type", "text");

     input.style.padding = "4px";
     input.style.width = "100%";
     input.style.boxSizing = "border-box";
     input.value = cellValue;

    onRendered(function(){
      input.focus();
      input.style.height = "100%";
    });

    // input.addEventListener("blur", (event) => {
    //   console.log('blur');
    //   success(input.value);
    // });

    // function onChange(){
    //   if(input.value != cellValue){
    //       success(input.value);
    //       //let cellData = cell.getData()
    //   }else{
    //       cancel();
    //   }

    // //submit new value on blur or change
    // input.addEventListener("blur", onChange);

    // //submit new value on enter
    // input.addEventListener("keydown",(event) =>{
    //     if(event.key === 13){
    //         onChange();
    //     }

    //     if(event.key === 27){
    //         cancel();
    //     }
    // });

    function Result_Mouse(){
      if(input.value != cellValue){
        success(input.value);
        console.log('mouse');           
          }else{
              cancel();
          }
    };

    input.addEventListener("blur", Result_Mouse);

    //}
    
    return input;

    // function onChange(){
    //   if(input.value != cellValue){
    //     success(input.value);
    //     //console.log('enter');
    //     //console.log(cell.getData());
    //     var cellData = cell.getData();
    //      //console.log(cellData.id);
    //       //change_user();			
    //   }else{
    //       cancel();
    //   }
    // };
	
  }

  const rowSelection = (data, rows) =>{
     console.log('выбор');
  }

  const columns_user = [
      {formatter:"rowSelection", width:10, titleFormatter:"rowSelection", hozAlign:"center",resizable:false, headerSort:false, 
       cellClick:function(e, cell){
         cell.getRow().toggleSelect();
         console.log(TableRef);
      }},
      { title: "№", field: "num", resizable:false, width: 50 , hozAlign: "center",headerSort : false},
      { title: "Контрагент", editor: dateEditor, field: "edit1",resizable:false , hozAlign: "center" , headerFilter:"input",headerFilterPlaceholder:"поиск...",formatter:"textarea"},
    ];


    const options_user = {
      //height: 150,
      //movableRows: true,
      //movableColumns: true
      //printAsHtml:true,
      //printHeader:"<h1>Example Table Header<h1>",
      //printFooter:"<h2>Example Table Footer<h2>",
      //printStyled : true,
      //history:true,
      pagination:"local",
      paginationSize:100,
      paginationSizeSelector:[10, 50, 100],
      layout:"fitColumns", // fit columns to width of table (optional)
      layoutColumnsOnNewData : true
    };


    useEffect(() => { 
      //Load_events();
      load_table_area();
    },[])    


const rowClick =()=>{
  //console.log('row_click')
}

// загрузка area контрагенты
const load_table_area = async () => {
  try {
      let data1;
      setLoading(true);
      data1 = await load_area_table();
      setevents(data1.data_out);
      console.log(data1.data_out);
      setLoading(false);
      //console.log(Object.keys(data1.data_out).length);
      let array_out_mas = [];	 
      let json_obj;
      for (let i = 0; i < Object.keys(data1.data_out).length; i++) {      
        json_obj = data1.data_out[i];       
        json_obj["num"] = i+1;
        array_out_mas.push(json_obj);
      } 
      //console.log(array_out_mas);
  } catch (e) {
      return [];
  }
  
  

}

if (loading) {
   return <Modal_User close_modal={true}/>//<Spinner animation={"grow"}/>      
}


  return(
      <>      
      <Navbar bg="light rounded" variant="light" sticky="top" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#">МЕНЮ</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link onClick={() => navigate(TABLEREPORT_ROUTE)}>Отчеты</Nav.Link>
            <Nav.Link onClick={() => navigate(COUNTERPARTIES_ROUTE)}>Контрагенты</Nav.Link>
            <Nav.Link onClick={() => navigate(HOTELROOMS_ROUTE)}>Номера</Nav.Link>
            <Nav.Link onClick={() => navigate(CALENDAR_ROUTE)}>Календарь</Nav.Link>
            <Nav.Link onClick={() => navigate(TABLE_ROUTE)}>Таблица</Nav.Link>
            
            {/*<NavDropdown title="Вид" id="navbarScrollingDropdown">
              <NavDropdown.Item onClick={() => navigate(CALENDAR_ROUTE)}>Календарь</NavDropdown.Item>
                <NavDropdown.Item  onClick={() => navigate(TABLE_ROUTE)}>
                Таблица
                </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Что-то другое
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>         
          
        </Navbar.Collapse>
      </Container>
    </Navbar> 
    <br></br> 
    <Form className="d-flex justify-content-start">
        <Button variant="outline-secondary" style={{paddingRight: "20px"}}>Добавить <i className="fa fa-plus"></i></Button> 
        <Button variant="outline-danger">Удалить <i className="fa fa-minus"></i></Button> 
    </Form>     
    <br></br>
            <ReactTabulator
             onRef={(r) => (TableRef = r)}
             data={events}
             columns={columns_user}
             options={options_user}
             //rowSelectionChanged={rowSelection}
             rowSelectionChanged={function(data, rows) {
              console.log(data);
            }}
            //  events={{
            //   rowClick: rowClick
            //  }}
             //cellEdited={cellClick}
            />
      </>
  );
  
}); 

export default ReactTabulatorCounterpartiesJs;
import React, {useEffect, useState, useRef} from "react";
//import { Context } from "../index";
import { observer } from "mobx-react";
import { useNavigate } from 'react-router-dom'

// ReactTabulator
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import "react-tabulator/css/bootstrap/tabulator_bootstrap4.min.css"; // use Theme(s)

import { ReactTabulator } from 'react-tabulator'
//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

// Bootstrap 5
import Container from "react-bootstrap/Container";
//import { NavDropdown } from "react-bootstrap";
//import { Form } from 'react-bootstrap'
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {Button} from "react-bootstrap";

import {TABLEREPORT_ROUTE,CALENDAR_ROUTE,TABLE_ROUTE,COUNTERPARTIES_ROUTE,HOTELROOMS_ROUTE} from "../utils/const";

import {load_area_table} from "../http/userApi";

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faClose } from '@fortawesome/free-solid-svg-icons'
//import { FaLock,FaLockOpen } from 'react-icons/fa';

import Modal_User from '../fullcalendar/Modl' 


export const ReactTabulatorRoomsJs = observer(() => {
  //const {user} = useContext(Context)
  const navigate = useNavigate()
  const [events,setevents] = useState([]);
  // Стейт loading
  const [loading, setLoading] = useState(false)

  // Итервал поиска
  const [startDate,setstartDate] = useState();
  const [endDate,setendDate] = useState();

  const [startDateSearch,setstartDateSearch] = useState();
  const [endDateSearch,setendDateSearch] = useState();
  
  const [SearchHiden,setSearchHiden] = useState(true);
  const [SearchButton,setSearchButton] = useState(true);


  const [value, onChange] = useState([new Date(), new Date()]);
  
  //const DateRangePickerRef = React.createRef();
  // ref table
  let TableRef = useRef(null);
  

  const columns_user = [
      { title: "id", field: "id", resizable:false, width: 50 , hozAlign: "center",headerSort : false},
      { title: "Контрагент", field: "edit1",resizable:false, width: 500 , hozAlign: "center" , headerFilter:"input",headerFilterPlaceholder:"поиск...",formatter:"textarea"},
    ];


    const options_user = {
      //height: 150,
      //movableRows: true,
      //movableColumns: true
      //printAsHtml:true,
      //printHeader:"<h1>Example Table Header<h1>",
      //printFooter:"<h2>Example Table Footer<h2>",
      //printStyled : true,
      //history:true,
      pagination:"local",
      paginationSize:100,
      paginationSizeSelector:[10, 50, 100],
      layout:"fitColumns", // fit columns to width of table (optional)
      layoutColumnsOnNewData : true
    };


    useEffect(() => { 
      //Load_events();
      load_table_area();
    },[])    


const rowClick =()=>{
  console.log('row_click')
}

// загрузка area контрагенты
const load_table_area = async () => {
  try {
      let data1;
      setLoading(true);
      data1 = await load_area_table();
      setevents(data1.data_out);
      setLoading(false);
  } catch (e) {
      return [];
  }
  
  

}

if (loading) {
   return <Modal_User close_modal={true}/>//<Spinner animation={"grow"}/>      
}


  return(
      <>      
      <Navbar bg="light rounded" variant="light" sticky="top" expand="lg">
      <Container fluid>
        <Navbar.Brand href="#">МЕНЮ</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link onClick={() => navigate(TABLEREPORT_ROUTE)}>Отчеты</Nav.Link>
            <Nav.Link onClick={() => navigate(COUNTERPARTIES_ROUTE)}>Контрагенты</Nav.Link>
            <Nav.Link onClick={() => navigate(HOTELROOMS_ROUTE)}>Номера</Nav.Link>
            <Nav.Link onClick={() => navigate(CALENDAR_ROUTE)}>Календарь</Nav.Link>
            <Nav.Link onClick={() => navigate(TABLE_ROUTE)}>Таблица</Nav.Link>
            
            {/*<NavDropdown title="Вид" id="navbarScrollingDropdown">
              <NavDropdown.Item onClick={() => navigate(CALENDAR_ROUTE)}>Календарь</NavDropdown.Item>
                <NavDropdown.Item  onClick={() => navigate(TABLE_ROUTE)}>
                Таблица
                </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Что-то другое
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>         
          
        </Navbar.Collapse>
      </Container>
    </Navbar> 
    <br></br>
            <ReactTabulator
             onRef={(r) => (TableRef = r)}
             data={events}
             columns={columns_user}
             options={options_user}
             events={{
              rowClick: rowClick
             }}
            />
      </>
  );
  
}); 

export default ReactTabulatorRoomsJs;
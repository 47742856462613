import axios from "axios";
//const url = 'http:/localhost:5000/';
//const url = 'http://localhost/';
const url = 'https://shanshotel.kz/';

//axios.defaults.withCredentials = true;
//axios.defaults.withCredentials = true;
axios.defaults.withCredentials = true

const $host = axios.create({
    //baseURL: process.env.REACT_APP_API_URL
    baseURL: url,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'//,
        //'Accept': 'application/json',
        //'Content-Type': 'application/x-www-form-urlencoded, multipart/form-data'
    }
})

const $authHost = axios.create({
    //baseURL: process.env.REACT_APP_API_URL
    baseURL: url,
    //withCredentials: true,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})


const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)
//$host.interceptors.request.use(authInterceptor)

// экспрортируем $host $authHost
export {
    $host,
    $authHost
}
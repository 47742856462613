import React from 'react';
import {useNavigate} from 'react-router-dom'
import {Button} from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Form } from 'react-bootstrap'

import {ADMIN_ROUTE, SHOP_ROUTE, LOGIN_ROUTE, CABINET_ROUTE, CALENDAR_ROUTE,TABLE_ROUTE,WEBS0CKET_ROUTE,REGISTRATION_ROUTE,PASSWORD_RECOVERY,HOTEL_ROUTE} from "../utils/const";

const Admin = () => {
    const navigate = useNavigate()
    return (
    <> 
        <br></br>
        <Form className="d-flex justify-content-center" >
           <h2>Панель Администратора</h2>
        </Form> 
        <br></br>
    <ButtonGroup aria-label="Basic example">
      <Button variant="secondary" onClick={() => navigate(SHOP_ROUTE)}>Начальная страница</Button>
      <Button variant="secondary" onClick={() => navigate(CALENDAR_ROUTE)}>Календарь</Button>
      <Button variant="secondary" onClick={() => navigate(REGISTRATION_ROUTE)}>Регистрация</Button>
      <Button variant="secondary" onClick={() => navigate(PASSWORD_RECOVERY)}>Востановление пароля</Button>
      <Button variant="secondary" onClick={() => navigate(HOTEL_ROUTE)}>Hotel</Button>
    </ButtonGroup>
    
    </>      
    );
};

export default Admin;

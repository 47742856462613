import React, {useRef, useContext, useState} from 'react';
import {Container, Form} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel"
import Row from "react-bootstrap/Row";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {Context} from "../index";
import {LOGIN_ROUTE,REGISTRATION_ROUTE,SHOP_ROUTE,PASSWORD_RECOVERY} from '../utils/const';
import {login, registration} from "../http/userApi";
import 'react-phone-number-input/style.css'
import '../css/Phone.css';
import '../css/loginpage.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faSignInAlt,faChartGantt} from "@fortawesome/free-solid-svg-icons";

import InputGroup from 'react-bootstrap/InputGroup';


const eye = <FontAwesomeIcon icon={faEye} />;
const eyeslash = <FontAwesomeIcon icon={faEyeSlash} />;

export const Auth = observer(() => {
   
    const {user} = useContext(Context)
    const location = useLocation()
    const navigate = useNavigate()
    const isLogin = location.pathname === LOGIN_ROUTE
    
    const [email, setEmail] = useState('')
    const [email_reg, setEmail_reg] = useState('')
    const [password, setPassword] = useState('')
    const [password_reg, setPassword_Reg] = useState('')
    const [password_confirm, setPassword_Confirm] = useState('')
    const [emailauth, setEmailAuth] = useState('')
// валидация
    const [validated, setValidated] = useState(false);
    const [validated_pass, setValidated_pass] = useState(false);
    const [validated_pass_confim, setValidated_pass_confim] = useState(false);
// валидация ввода пароля при регистрации 
    const [validated_1DIG, setValidated_1DIG] = useState(false);
    const [validated_StrLat, setValidated_StrLat] = useState(false);
    const [validated_StrCapsLat, setValidated_StrCapsLat] = useState(false);
    const [validated_StrCahrLat, setValidated_StrCahrLat] = useState(false);
    const [validated_length9, setValidated_length9] = useState(false);

    
    const [showPass, setShowPass] = useState(false);
    const [LoginPassType, setLoginPassType] = useState("password");

    const EmailStr = useRef();

// чистим поля
const ClearFieldRegistrationRoute = () => {
    setEmail('')
    setEmail_reg('')
    setEmailAuth('')
    setPassword_Reg('')
    setPassword_Confirm('')
    setValidated(false)
    setValidated_pass(false)
    setValidated_pass_confim(false)
}
const ClearFieldLoginRoute = () => {
    setEmail('')
    setPassword('')
}


// показать скрыть пароль input password
const clickHandlerEye = () => {
    if (showPass){
        setShowPass(false);
        setLoginPassType("password");
    } else {
        setShowPass(true);
        setLoginPassType("text");
    }   
}
      
// нажатие 
    const handleKeyPress = (event) => {
   
    if (validated_1DIG && validated_StrLat && validated_StrCapsLat && validated_StrCahrLat && validated_length9) {
        setValidated_pass(false)
    } else {
        setValidated_pass(true)
    }

    if (event.target.value !== ''){
        //setValidated_pass(true)
        //console.log("True")
    } else {
        //console.log("False")
        setValidated_pass(false)
    }


    //    if(event.key === 'Enter'){
    //      //console.log('enter press here! ')
    //    }
    //    if(event.key === '1'){
            //console.log('enter press here! ')
            //setEmail('');
    //      }
      }

      



// изменение тест Password_reg Input
const handleOnChangePassReg = (event) => {
    setPassword_Reg(event.target.value)
    

        // проверка совпадают пароли или нет
        if (event.target.value === password_confirm) {
             //console.log('Email is invalid');
              setValidated_pass_confim(false);
            } else {
             // console.log('Email is valid');
              setValidated_pass_confim(true);
            }

          const valid1Dig= new RegExp("\\d"); // Одна цифра
          const validStrLat= new RegExp("\[a-z]"); // Cтрочные буквы латинские
          const validStrCapsLat= new RegExp("\[A-Z]"); // Заглавные буквы латинские
          //const validStrCahrLat= new RegExp("\\W"); // Один спецсимвол
          const validStrCahrLat= new RegExp("[!@#$%^&*]"); // Один спецсимвол
          //const validStrCahrLat = (/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(event.target.value));

   
          if (!valid1Dig.test(event.target.value)) {
               //console.log('Error 1 DIG!')
               setValidated_1DIG(false); 
            }
             else {
               //console.log('Ok 1 DIG!')
               setValidated_1DIG(true);
            }
        
          if (!validStrLat.test(event.target.value)) {
               //console.log('Error STR LAT!')
               setValidated_StrLat(false)
            }
             else {
               //console.log('Ok STR LAT!')
               setValidated_StrLat(true)
            }

          if (!validStrCapsLat.test(event.target.value)) {
              //console.log('Error STR CAPS LAT!')
              setValidated_StrCapsLat(false)
            }
             else {
              //console.log('Ok STR CAPS LAT!')
              setValidated_StrCapsLat(true)
            }

          if (!validStrCahrLat.test(event.target.value)) {
              //console.log('Error STR Cahr LAT!')
              setValidated_StrCahrLat(false)
            }
             else {
              //console.log('Ok STR Cahr LAT!')
              setValidated_StrCahrLat(true)
            }

          if ((event.target.value).length < 9){
                //console.log('Error NO 9 DIG!') 
                setValidated_length9(false)                  
            } 
             else{
               //console.log('OK > 9 DIG!')
               setValidated_length9(true)         
            }

}

// изменение тест Password_confirm Input
const handleOnChangePassConfirm = (event) => {
    setPassword_Confirm(event.target.value)
    if (password_reg === event.target.value) {
       //console.log('Email is invalid');
        setValidated_pass_confim(false);
      } else {
       // console.log('Email is valid');
        setValidated_pass_confim(true);
      } 
}

// функция проверки email
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

// изменение тест Email Input
    const handleOnChangeEmailAuth = (event) => {
        setEmailAuth(event.target.value)
        if (!isValidEmail(EmailStr.current) && event.target.value !== '') {
           // console.log('Email is invalid');
            setValidated(true);
          } else {
           // console.log('Email is valid');
            setValidated(false);
          }
        EmailStr.current = event.target.value;   
    }

// изменение Tel Login Input
    const handleOnChangeLogin = (event) => {
        setEmail(event.target.value)
        var myStr = event.target.value;
        //var newStr = myStr.replace(/[^0-9\.]/g, '');
        var newStr = myStr.replace(/^(?:(\+?\d*)?[^\d]*)/g, '$1');
        setEmail(newStr);        
    }
// изменение Tel Reg Input
    const handleOnChangeReg = (event) => {
        setEmail_reg(event.target.value)
        var myStr = event.target.value;
        //var newStr = myStr.replace(/[^0-9\.]/g, '');
        var newStr = myStr.replace(/^(?:(\+?\d*)?[^\d]*)/g, '$1');
        setEmail_reg(newStr);        
    }   
      
// нажатие вход
    const click = async (event) => {
        event.preventDefault();
        try {
            let data;
            if (isLogin) {
                data = await login(email, password);
                setValidated(true);
                user.setUser(user)
                user.setIsAuth(true)
                user.setRole(data.role)
                user.setEmail(data.email)
                navigate(SHOP_ROUTE);
                console.log(data)
            } else {
                data = await registration(email_reg, password_reg, emailauth);
                console.log(data);
                ClearFieldRegistrationRoute();
                navigate(LOGIN_ROUTE);
            }

        } catch (e) {
            //alert('False '+ e.response.data.message)
            alert(e.response.data.message)
            //console.log(e.response.data)
        }

    }

    return (
        <Container
            className="d-flex justify-content-center align-items-center"
            style={{height: window.innerHeight - 54}}
        >
            <Card style={{width: 400}} className="p-5">
               <h4 className="m-auto">
                   {isLogin ? 
                    'Вход'
                   : 
                    'Регистрация'
                   }
               </h4>
            <br></br>


                <Form onSubmit={click} className="d-flex flex-column">
                {isLogin ?
                   <div>
                    <Form.Control 
                        id="tel"
                        maxLength="12"
                        //onKeyUp={handleKeyPress}
                        required
                        className="mt-3"
                        placeholder="Номер телефона"
                        value={email}
                        onChange={handleOnChangeLogin}
                    />  
                <InputGroup>                      
                    <Form.Control
                        required
                        className="mt-3"
                        placeholder="Пароль"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        //onKeyUp={handleKeyPress}
                        type={LoginPassType}
                    />
                    <InputGroup.Text className="mt-3"><i onClick={clickHandlerEye}>{showPass? eye:eyeslash}</i></InputGroup.Text>

                </InputGroup>
                   </div>
                :
                   <div>
                    <Form.Control
                        maxLength="12"
                        required
                        className="mt-3"
                        placeholder="Номер телефона*"
                        value={email_reg}
                        onChange={handleOnChangeReg}
                    />

                <InputGroup hasValidation>  
                    <Form.Control
                        required
                        className="mt-3"
                        placeholder="Укажите ваш email*"
                        value={emailauth}
                        onChange={handleOnChangeEmailAuth}
                        isInvalid={validated}
                    />
                    <Form.Control.Feedback type="invalid">
                       Неправильный формат email
                    </Form.Control.Feedback>
                </InputGroup>
                
                <InputGroup hasValidation>
                    <Form.Control
                        required
                        className="mt-3"
                        placeholder="Пароль*"
                        value={password_reg}
                        //onChange={e => setPassword_Reg(e.target.value)}
                        onKeyUp={handleKeyPress}
                        onChange={handleOnChangePassReg}
                        isInvalid={validated_pass}
                        type="password"
                    />
                    <Form.Control.Feedback type="invalid">
                      <div style={validated_1DIG? {color: "green"}:{color: "red"}}>Минимум одну цифру</div>
                      <div style={validated_StrLat && validated_StrCapsLat? {color: "green"}:{color: "red"}}>Заглавные буквы и строчные буквы</div>
                      <div style={validated_StrCahrLat? {color: "green"}:{color: "red"}}>Минимум один спецсимвол </div>
                      <div style={validated_StrCahrLat? {color: "green"}:{color: "red"}}>(!, @, #, $, %, ^, &, *, _)</div>
                      <div style={validated_length9? {color: "green"}:{color: "red"}}>Не менее 9 символов</div>          
                    </Form.Control.Feedback>
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Control
                        required
                        className="mt-3"
                        placeholder="Подтвердите пароль*"
                        value={password_confirm}
                        //onChange={e => setPassword_Confirm(e.target.value)}
                        onChange={handleOnChangePassConfirm} 
                        type="password"
                        isInvalid={validated_pass_confim}
                    />
                    <Form.Control.Feedback type="invalid">
                       Пароли не совпадают
                    </Form.Control.Feedback>
                </InputGroup>
                   </div>
                }


                    {isLogin ?
                               <Button  className="mt-3" type="submit" disabled={!email || !password}
                                variant={"outline-success"}
                                onClick={click}
                               //onClick={handleSubmit}
                                >Войти&nbsp;
                                <FontAwesomeIcon icon={faSignInAlt} />
                               </Button>
                            :
                               <Button className="mt-3" type="submit" disabled={validated_pass ||validated_pass_confim || validated || !email_reg || !emailauth || !password_reg || !password_confirm}
                               variant={"outline-success"}
                               onClick={click}
                               //onClick={handleSubmit}
                                >Регистрация
                               </Button>
                    }   
<br></br>
                    {isLogin ?
                            <div>
                                {/*Нет аккаунта? <NavLink onClick={ClearFieldLoginRoute} to={REGISTRATION_ROUTE}>Зарегистрируйся!</NavLink>
                                <br></br>
                                  Забыли пароль? <NavLink onClick={ClearFieldLoginRoute} to={PASSWORD_RECOVERY}>Воcстановить!</NavLink>*/}
                            </div>
                            :
                            <div>
                                Есть аккаунт? <NavLink onClick={ClearFieldRegistrationRoute} to={LOGIN_ROUTE}>Войдите!</NavLink>
                            </div>
                    }

                </Form>


            </Card>
        </Container>
    );

});
import React from 'react';
//import {ReactTabulatorJsHotel} from '../Tabulator/TabulatorHotel'
import { Form } from 'react-bootstrap'
import ReactTabulatorRoomsJs from '../Tabulator/TabulatorRooms';

const HotelRooms = () => {

    return (
        <>
          <br></br>
          <div style={{textAlign: "center"}}><h2>Номера</h2></div>
          <hr></hr>
          <ReactTabulatorRoomsJs/>
          <Form className="d-flex justify-content-end">
          
          </Form>
          
        </>
    );
};

export default HotelRooms;
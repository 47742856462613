import React,{createContext} from 'react';
import './css/index.css';

//import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client';


import App from './App';
import UserStore from './store/UserStore';
import { CookiesProvider } from "react-cookie";

export const Context = createContext(null)

//const root = ReactDOM.createRoot(document.getElementById('root'));
//const container = document.getElementById('root');
//const root = createRoot(container);

createRoot(document.getElementById('root')).render(
   <CookiesProvider>
      <Context.Provider value={{
         user: new UserStore()
      }}>
         <App/> 
      </Context.Provider>
   </CookiesProvider>  
);

// root.render(
//   <CookiesProvider>
//      <Context.Provider value={{
//         user: new UserStore()
//      }}>
//         <App/> 
//      </Context.Provider>
//   </CookiesProvider>  
// );
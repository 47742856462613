import React /*,{ useContext }*/ from "react";
//import { Context } from "../index";
import {ReactTabulatorJs} from '../Tabulator/Tabulator'

//import Container from "react-bootstrap/Container";
//import Navbar from "react-bootstrap/Navbar";
//import { NavDropdown } from "react-bootstrap";
//import { Form } from 'react-bootstrap'
//import Nav from "react-bootstrap/Nav";
//import { NavLink } from "react-router-dom";
//import {ADMIN_ROUTE, SHOP_ROUTE, LOGIN_ROUTE, CABINET_ROUTE, CALENDAR_ROUTE,TABLE_ROUTE,WEBS0CKET_ROUTE} from "../utils/const";
//import {useNavigate} from 'react-router-dom'

const Table = () => {
  //const {user} = useContext(Context)
 //const navigate = useNavigate()

    return (
        <>
          <br></br>
          <div style={{textAlign: "center"}}><h2>Таблица</h2></div>
          <hr></hr>
          <ReactTabulatorJs/>
        </>
    );
};

export default Table;
import React, {useContext, useEffect, useState} from 'react';
import { BrowserRouter } from "react-router-dom";
import { AppRouter} from './components/AppRouter';
import { NavBar } from './components/NavBar';
import {observer} from "mobx-react";
import {Context} from "./index";
import {check} from './http/userApi';
//import {Spinner} from "react-bootstrap";
import Modal_User from '../src/fullcalendar/Modl' 



const App = observer(() => {
  const {user} = useContext(Context)
  const [loading, setLoading] = useState(true)
 useEffect(() => { 
   check().then(data => {
       if (data != null){
         //console.log(data) // выводим роль в консоль
         user.setUser(true)
         user.setIsAuth(true)
         user.setRole(data.role)
         user.setEmail(data.email)
       }
   }).finally(() => setLoading(false))
}, [user])



  if (loading) {
      return <Modal_User close_modal={true}/>//<Spinner animation={"grow"}/>      
  }

  return (
      <BrowserRouter>
          <NavBar />
          <AppRouter />
      </BrowserRouter>
  );
});

export default App;
import React, { useContext } from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import {authUserRoutesHotel, AuthRoutes, authUserRoutes, authAdminRoutes, publicRoutes} from "../routes";
import {Context} from "../index";
import {observer} from "mobx-react";


export const AppRouter = observer(() => {
    const {user} = useContext(Context)
   // console.log(user.role);
    
    var HotelRoute = false
    if(user.isAuth && user.role ==='HOTEL'){
        HotelRoute = true
    }  
    
    var AdminRoute = false
    if(user.isAuth && user.role ==='ADMIN'){
        AdminRoute = true
    }
    
    var UserRoute = false
    if(user.isAuth && user.role ==='USER'){
        UserRoute = true
    }
    
    var AuthRoute = true
    if(user.isAuth){
        AuthRoute = false
    }
      //console.log(user.isAuth)
      //console.log(user)
    return (
              <Routes>

                {HotelRoute && authUserRoutesHotel.map(({path, Component}) =>                
                   <Route key={path} path={path} element={<Component/>}/>
                )}  

                {AdminRoute && authAdminRoutes.map(({path, Component}) =>                
                   <Route key={path} path={path} element={<Component/>}/>
                )}
                
                {UserRoute && authUserRoutes.map(({path, Component}) =>                
                   <Route key={path} path={path} element={<Component/>}/>
                )}

          {/*     {user.isAuth && authUserRoutes.map(({path, Component}) =>                
                   <Route key={path} path={path} element={<Component/>}/>
                )}   */} 
            
                {publicRoutes.map(({path, Component}) =>
                   <Route key={path} path={path} element={<Component/>}/>
                )}

                {AuthRoute && AuthRoutes.map(({path, Component}) =>
                   <Route key={path} path={path} element={<Component/>}/>
                )}
                   <Route path="*" element={<Navigate to="/" replace />}></Route>
              </Routes>
    );
});


//export class AppRouter extends React.Component{
//    render(){
//        const isAuth = false;
//        return(          
//              <Routes>
//                {isAuth && authRoutes.map(({path, Component}) =>
//                   <Route key={path} path={path} element={<Component/>}/>
//                )}
//
//                {publicRoutes.map(({path, Component}) =>
//                   <Route key={path} path={path} element={<Component/>}/>
//                )}
//              </Routes>
//        )
//
//    }
//}
import React, { useContext } from "react";
import {useNavigate} from 'react-router-dom'
import { Context } from "../index";
import { Form } from 'react-bootstrap'
import {ADMIN_ROUTE, SHOP_ROUTE, LOGIN_ROUTE, CABINET_ROUTE, CALENDAR_ROUTE,TABLE_ROUTE,WEBS0CKET_ROUTE} from "../utils/const";
import ReactTabulatorCounterpartiesJs from "../Tabulator/TabulatorCounterparties"
const Counterparties = () => {
  const {user} = useContext(Context)
  const navigate = useNavigate()

    return (
        <>
          <br></br>
          <div style={{textAlign: "center"}}><h2>Контрагенты</h2></div>
          <hr></hr>
          <ReactTabulatorCounterpartiesJs/>
          <Form className="d-flex justify-content-end">
          
          </Form>
        </>
    );
};

export default Counterparties;
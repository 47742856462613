export const PASSWORD_RECOVERY = '/passwordrecovery' 
export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const SHOP_ROUTE = '/'
export const CABINET_ROUTE = '/cabinet'
export const CALENDAR_ROUTE = '/calendar'
export const TABLE_ROUTE = '/table'
export const HOTEL_ROUTE = '/hotel'
export const TABLEREPORT_ROUTE = '/report'
export const COUNTERPARTIES_ROUTE = '/counterparties'
export const HOTELROOMS_ROUTE = '/hotelrooms'
export const WEBS0CKET_ROUTE = '/websocket'
export const ERORR_ROUTE = '*'
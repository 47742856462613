import React, { useContext } from "react";
import { Context } from "../index";
import {observer} from "mobx-react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import {ADMIN_ROUTE, SHOP_ROUTE, LOGIN_ROUTE, CABINET_ROUTE, CALENDAR_ROUTE,HOTEL_ROUTE, WEBS0CKET_ROUTE} from "../utils/const";
import {Button} from "react-bootstrap";
import {useNavigate} from 'react-router-dom'
import {logoutauth} from '../http/userApi'
import LogoImg from "../image/icon-hotel.png"
//import { FaLock,FaLockOpen } from 'react-icons/fa';


export const NavBar = observer(() => {
    const {user} = useContext(Context)
    const navigate = useNavigate()

    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
        user.setRole({})
        logoutauth()
    }



  return(
    <Navbar bg="light rounded" variant="light">
    <Container fluid>
        <Navbar.Brand href="/">
            <img
              src={LogoImg}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
        </Navbar.Brand>

        <Nav className="me-auto">
            <NavLink style={{color:'black',textDecoration: 'none',marginRight:'10px'}} to={SHOP_ROUTE}>SHANS_HOTEL</NavLink>
              {user.isAuth ?
              <div>
                 {/*<NavLink style={{color:'black',textDecoration: 'none',marginRight:'10px'}} to={CALENDAR_ROUTE}>Календарь</NavLink>*/}
              </div>
                  :
                 <div></div>
              }
        </Nav>


        {user.isAuth ?
            <Nav className="ml-auto" style={{color: 'black'}}>
            { user.role === 'USER' ?
                <Button
                    variant={"outline-secondary"}
                    onClick={() => navigate(CALENDAR_ROUTE)}
                >
                     Гостиница
                </Button>
                :            
                <div></div>
            }
            { user.role === 'HOTEL' ?
                <Button
                    variant={"outline-secondary"}
                    onClick={() => navigate(HOTEL_ROUTE)}
                >
                     Гостиница
                </Button>
                :            
                <div></div>
            }
            { user.role === 'ADMIN' ?
                <><Button
                    variant={"outline-secondary"}
                    onClick={() => navigate(CALENDAR_ROUTE)}
                >
                    Гостиница
                </Button>
                <Button
                    variant={"outline-secondary"}
                    onClick={() => navigate(ADMIN_ROUTE)}
                >
                    Админ панель
                </Button></>
                :
                <Button
                    variant={"outline-secondary"}
                    onClick={() => navigate(CABINET_ROUTE)}
                >
                   Кабинет пользователя {user.email}
                </Button>
            }  
                <Button
                    variant={"outline-secondary"}
                    onClick={() => logOut()}
                    className="ml-2"
                >
                    Выйти
                </Button>
               {/*<Button
                    variant={"outline-secondary"}
                    onClick={() => navigate(WEBS0CKET_ROUTE)}
                >
                    WEBSOCKET
                </Button>*/} 
            </Nav>
            :
            <Nav className="ml-auto" style={{color: 'black'}}>
                <Button  style={{width: '150px'}} variant={"outline-secondary"} onClick={() => navigate(LOGIN_ROUTE)}>Вход</Button>
            </Nav>
        }
    </Container>
   </Navbar>
  );

  
}); 
import React from 'react';
//import {Context} from "../index";
//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
//import Card from 'react-bootstrap/Card'
//import {observer} from "mobx-react";
import Spinner from 'react-bootstrap/Spinner';
//import {add_event} from '../fullcalendar/FullCalendar'

export function Modal_User ({close_modal}) {
       return (
        <>
        <Modal id="myModal"
          show={close_modal}
          onHide={close_modal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <Spinner animation="border" role="status">             
              <span className="visually-hidden"></span>             
            </Spinner>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default Modal_User;

// export const Modal_User = () => {

//   const textInput = React.createRef();


//    // const {device} = useContext(Context)
//    const {user} = useContext(Context)
//    const [show, setShow] = useState(false);

//    useEffect(() => { 
//     console.log(user.modal1);
//     setShow(user.modal1);
//    }, [user])
 
   
//     const handleClose = () => {
//       setShow(false)
//     };

    
//     const handleShow = () => {
//       setShow(true)
//     };


//     return (
//         <>
//         <Modal id="myModal"
//           show={true}
//           onHide={handleClose}
//           backdrop="static"
//           keyboard={false}
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>+ Добавление</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//              <Card >
//                   <Card.Body>
//                       <Card.Title>Card Title</Card.Title>
//                         <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
//                           <Card.Text>
//                              Some quick example text to build on the card title and make up the
//                              bulk of the card's content.
//                           </Card.Text>
//                       <Card.Link href="#">Card Link</Card.Link>
//                       <Card.Link href="#">Another Link</Card.Link>
//                   </Card.Body>
//                </Card>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={handleClose}>
//               Закрыть
//             </Button>
//             <Button variant="danger" onClick={handleShow}>
//               Добавить
//             </Button>
//           </Modal.Footer>
//         </Modal>
//       </>
//     );




// };

// export default Modal_User;
import Admin from "./pages/Admin";
import {HOTEL_ROUTE,TABLEREPORT_ROUTE,PASSWORD_RECOVERY,ADMIN_ROUTE,LOGIN_ROUTE,HOTELROOMS_ROUTE
       ,CABINET_ROUTE,REGISTRATION_ROUTE,SHOP_ROUTE,CALENDAR_ROUTE,TABLE_ROUTE,COUNTERPARTIES_ROUTE/*WEBS0CKET_ROUTE*/} from "./utils/const";
import {Shop} from './pages/Shop'
import {Auth} from './pages/Auth';
import {PassRec} from './pages/PasswordRecovery'
import FullCalendar from './fullcalendar/FullCalendar'
//import ReactTabulatorJs from './Tabulator/Tabulator'
import Table from './pages/Table'
import TableReport from './pages/TableReport'
import Counterparties from './pages/Counterparties'

import CabinetUser from './pages/CabinetUser'
import CabinetUserHotel from './pages/Hotel'
import HotelRooms from './pages/HotelRooms'

//import websocketcompnent from "./pages/websocket";

export const authUserRoutesHotel = [
    {
        path: HOTEL_ROUTE,
        Component: CabinetUserHotel
    },
    {
        path: CABINET_ROUTE,
        Component: CabinetUser
    },
    {
        path: TABLEREPORT_ROUTE,
        Component: TableReport
    }
]

export const authAdminRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: Admin
    },
    {
        path: CABINET_ROUTE,
        Component: CabinetUser
    },
    {
        path: CALENDAR_ROUTE,
        Component: FullCalendar
    },
    {
        path: REGISTRATION_ROUTE,
        Component: Auth
    },
    {
        path: PASSWORD_RECOVERY,
        Component: PassRec
    },
    {
        path: TABLE_ROUTE,
        Component: Table
    },
    {
        path: TABLEREPORT_ROUTE,
        Component: TableReport
    },
    {
        path: COUNTERPARTIES_ROUTE,
        Component: Counterparties
    },
    {
        path: HOTELROOMS_ROUTE,
        Component: HotelRooms
    },
    {
        path: HOTEL_ROUTE,
        Component: CabinetUserHotel
    }
 //   {
 //       path: WEBS0CKET_ROUTE,
 //       Component: websocketcompnent
  //  },
]


export const authUserRoutes = [
    {
        path: CABINET_ROUTE,
        Component: CabinetUser
    },
    {
        path: CALENDAR_ROUTE,
        Component: FullCalendar
    },
    {
        path: TABLE_ROUTE,
        Component: Table
    },
    {
        path: TABLEREPORT_ROUTE,
        Component: TableReport
    },
    {
        path: COUNTERPARTIES_ROUTE,
        Component: Counterparties
    },
    {
        path: HOTELROOMS_ROUTE,
        Component: HotelRooms
    }
]

export const publicRoutes = [
    {
        path: SHOP_ROUTE,
        Component: Shop
    },

]

export const AuthRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
]
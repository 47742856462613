import {$authHost, $host} from "./index";
import jwt_decode from "jwt-decode";
//import { Cookies } from "react-cookie";



// получение информации об ивенте
export const load_info_event = async (id) => {
    try {
     const {data} = await $authHost.post('api/load/load_info_event',{id})
     localStorage.setItem('token', data.token)
     return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');
     window.location.reload();
    }  
}

// получение интервала до занятого
export const load_interval_free_event = async (start,nomer,resourceId) => {
    try {
     const {data} = await $authHost.post('api/load/interval_event_free',{start,nomer,resourceId})
     localStorage.setItem('token', data.token)
     return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');   
     window.location.reload();
    }  
}


// получение номера 
export const load_interval_event = async (start,end,nomer) => {
    console.log(nomer)
    try {
     const {data} = await $authHost.post('api/load/interval_event',{start,end,nomer})
     localStorage.setItem('token', data.token)
     return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');   
     window.location.reload();
    }  
}

// получение номера 
export const load_room = async () => {
    try {
     const {data} = await $authHost.post('api/load/room')
     localStorage.setItem('token', data.token)
     return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');   
     window.location.reload();
    }  
}

// получение места
export const load_place = async (room) => {
    try {
     const {data} = await $authHost.post('api/load/place',{room})
     localStorage.setItem('token', data.token)
     return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');    
     window.location.reload();
    }  
}

// получение ресурсов (Номера-Места-Тип)
export const load_resourceAreaColumns = async () => {
    try {
     const {data} = await $authHost.post('api/load/resourceAreaColumns')
     localStorage.setItem('token', data.token)
     return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');   
     window.location.reload();
    }  
}

// получение ресурсов (Номера-Места)
export const load_resources = async () => {
    try {
     const {data} = await $authHost.post('api/load/resources')
     //console.log(data)
     localStorage.setItem('token', data.token)
     return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');
     window.location.reload();
    }  
}

// поиск получателей в поле edit3
export const load_recipient = async (edit3) => {
    try {
     const {data} = await $authHost.post('api/load/recipient',{edit3})
     localStorage.setItem('token', data.token)
     return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');
     window.location.reload();
    } 
}


// поиск районов
export const load_area_table = async () => {
    try {
     const controller = new AbortController();
     const {data} = await $authHost.post('api/load/areatable',{signal: controller.signal})
     localStorage.setItem('token', data.token)
     controller.abort()
     //console.log(data);
    return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401' + e);   
     window.location.reload();
    } 
}

// поиск районов в поле edit1
export const load_area = async (edit1) => {
    try {
     const controller = new AbortController();
     const {data} = await $authHost.post('api/load/area',{edit1},{signal: controller.signal})
     localStorage.setItem('token', data.token)
     controller.abort()
     //console.log(data);
    return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401' + e);   
     window.location.reload();
    } 


  //  const controller = new AbortController();

   // axios.get('/foo/bar', {signal: controller.signal}).then(function(response) {
    //...
  //  });
    // отмена запроса
    //controller.abort()
}

// изменение event
export const events_change = async (event_title,event_id,event_region,event_order_number) => {
    try {
     const {data} = await $authHost.post('api/events/change',{event_title,event_id,event_region,event_order_number})
     localStorage.setItem('token', data.token)
     return data//jwt_decode(data.token)
     } catch (e) {
      //alert('Срок действия токена закончен, ошибка 401');
      window.location.reload();
     } 
}

// создание event
export const events_create = async (event_title,event_resourceId,event_start,event_end,event_backgroundColor,event_comments,event_nomer,event_region,event_order_number) => {
    try {
     const {data} = await $authHost.post('api/events/create',{event_title,event_resourceId,event_start,event_end,event_backgroundColor,event_comments,event_nomer,event_region,event_order_number})
     localStorage.setItem('token', data.token)
     return data//jwt_decode(data.token)
     } catch (e) {
      //alert('Срок действия токена закончен, ошибка 401');
      window.location.reload();
     } 
}

// удаление event
export const events_delete = async (event_id) => {
    try {
     const {data} = await $authHost.post('api/events/delete',{event_id})
     localStorage.setItem('token', data.token)
    return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');
     window.location.reload();
    } 
}

// загрузка event hotel
export const events_load_hotel = async (status1,status2,search) => {
    try {
     const {data} = await $host.post('api/events/loadhotel',{status1,status2,search})
     //localStorage.setItem('token', data.token)
     //console.log(data)
    return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');
     window.location.reload();
    } 
}

// изменение event hotel
export const events_change_hotel = async (id,status,status_color) => {
    try {
     const {data} = await $host.post('api/events/changehotel',{id,status,status_color})
     //localStorage.setItem('token', data.token)
     //console.log(data)
    return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');
     window.location.reload();
    } 
}
// загрузка event
export const events_load = async (date_start,date_end) => {
    try {
     const {data} = await $host.post('api/events/load',{date_start,date_end})
     //localStorage.setItem('token', data.token)
     //console.log(data)
    return data//jwt_decode(data.token)
    } catch (e) {
     //alert('Срок действия токена закончен, ошибка 401');
     window.location.reload();
    } 
}

// регистрация пользователя
export const registration = async (email, password, emailauth) => {
    const {data} = await $host.post('api/user/registration', {email, password, emailauth, role: 'USER'})
    //localStorage.setItem('token', data.token)
    return data//jwt_decode(data.token)
}

//const cookies = new Cookies();
// вход с логином и паролем
export async function login(email, password) {
    const {data} = await $host.post('api/user/login', {email, password})
    console.log(data);
    //localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}
// вход с логином и паролем
//export const login = async (email, password) => {
//    const {data} = await $host.post('api/user/login', {email, password})
//    AppCookies(data.token)
//    localStorage.setItem('token', data.token)
//    return jwt_decode(data.token)
//}
// проверяем авторизацию пользователя
export const check = async () => {
    const {data} = await $authHost.get('api/user/auth')
        //localStorage.setItem('token', data.token);
    return  jwt_decode(data.token);//jwt_decode('token',data.token)

  //  let token,localtoken = null;
    //const {data} = await $authHost.get('api/user/auth' ).then(res =>{
 //   const {data} = await $authHost.get('api/user/auth' ).then(res =>{
        //SetCookies(res.data.token);
 //       localStorage.setItem('token',res.data.token);
 //       console.log("Токен Получен" + data);
 //   }).catch(error => error)
 //       localtoken = localStorage.getItem('token')
 //   if (localtoken != null){
 //      token = jwt_decode(localStorage.getItem('token'))
 //      console.log("Токен из Local" + token)
        //console.log("JWT из Local" + localStorage.getItem('jwt'))
   // }
 //   return  token
}
// выход удаления токена
export const logoutauth = async () => {
    localStorage.clear('token');
    const {data} = await $authHost.get('api/user/logout',{ withCredentials: true });
    console.log(data);
}

import React from 'react';
import {Container, Form} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {LOGIN_ROUTE} from '../utils/const';
import {observer} from "mobx-react";
import {Context} from "../index";

export const PassRec = observer(() => {
   // const {device} = useContext(Context)

    return (
        <Container
            className="d-flex justify-content-center align-items-center"
            style={{height: window.innerHeight - 54}}
        >
            <Card style={{width: 400}} className="p-5">
            <h4 className="m-auto">Востановление Пароля</h4>
     
<br></br>

                <Form className="d-flex flex-column">                
                <InputGroup hasValidation>
                    <Form.Control
                        required
                        className="mt-3"
                        placeholder="Пароль*"
                        //value={password_reg}
                        //onChange={e => setPassword_Reg(e.target.value)}
                        //onKeyUp={handleKeyPress}
                        //onChange={handleOnChangePassReg}
                        //isInvalid={validated_pass}
                        type="password"
                    />
                  
                </InputGroup>

                <InputGroup hasValidation>
                    <Form.Control
                        required
                        className="mt-3"
                        placeholder="Подтвердите пароль*"
                        //value={password_confirm}
                        //onChange={e => setPassword_Confirm(e.target.value)}
                        //onChange={handleOnChangePassConfirm} 
                        type="password"
                        //isInvalid={validated_pass_confim}
                    />
                    <Form.Control.Feedback type="invalid">
                       Пароли не совпадают
                    </Form.Control.Feedback>
                </InputGroup>

     
                               <Button  className="mt-3" type="submit" 
                                variant={"outline-success"}
                                //onClick={click}
                               //onClick={handleSubmit}
                                >Создать новый пароль
                               </Button>
                               <br></br>
                               <div>
                                Вспомнил пароль <NavLink  to={LOGIN_ROUTE}>Войти!</NavLink>
                               </div>

                </Form>


            </Card>
        </Container>
    );


});

export default PassRec;